import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {ExperiencesVideoUrlParams} from '@/features/experiences-video/types.ts'

export const useExperiencesVideoUrlParams = () => {
    const urlParams = useURLParsedParams(ExperiencesVideoUrlParams)
    if (urlParams.success) {
        return urlParams.data
    }

    throw new Error('wrong urlParams')
}
