import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {httpGetExperiencesVideo} from '@/features/experiences-video/services/experiencesVideo.http.ts'
import {captureException} from '@sentry/react'
import {ExperiencesVideo} from '@/features/experiences-video/types.ts'

export const useExperiencesVideo = ({
    hostCode,
    hostPassword,
    experiencesVideoId
}: {
    hostCode: number
    hostPassword: string
    experiencesVideoId: number
}) =>
    useQuery({
        queryKey: [QUERY_KEYS.EXPERIENCES_VIDEO, hostCode, hostPassword, experiencesVideoId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetExperiencesVideo({
                    urlParams: {
                        hostCode: hostCode,
                        hostPassword: hostPassword,
                        experiencesVideoId: experiencesVideoId
                    }
                }),
                responseShape: ExperiencesVideo,
                onZodError: captureException
            })
    })
