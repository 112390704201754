import {
    handleCloseSlidingModal,
    SlidingModalCloseButton,
    SlidingModalState
} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {FC} from 'react'
import {
    StyledHeader,
    StyledSlidingModal
} from '@/features/experiences-video/components/completed-requirements-modal/style.ts'
import {useTranslation} from 'react-i18next'
import {FullRequirements} from '@/features/experiences-video/components/full-requirements/FullRequirements.tsx'
import {ExperiencesVideoModalBody} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

interface CompletedRequirementsModalProps {
    slidingModalState: SlidingModalState
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const CompletedRequirementsModal: FC<CompletedRequirementsModalProps> = ({
    slidingModalState,
    setSlidingModalState
}) => {
    const {t} = useTranslation()

    return (
        <StyledSlidingModal
            slidingModalState={slidingModalState}
            onOverlayClick={() => handleCloseSlidingModal(setSlidingModalState)}
        >
            <StyledHeader>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                <h2>{t('experiences_video:technical_requirements')}</h2>
            </StyledHeader>
            <ExperiencesVideoModalBody style={{paddingLeft: 0, paddingRight: 0}}>
                <FullRequirements />
            </ExperiencesVideoModalBody>
        </StyledSlidingModal>
    )
}
