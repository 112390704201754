import {useTranslation} from 'react-i18next'
import {
    StyledCancelDisclaimer,
    StyledCancelDisclaimerTooltip,
    StyledCancelStatsWidgetWrapper,
    StyledCancelStepDesc,
    StyledCancelStepFooter,
    StyledCancelStepHeader
} from '@/features/service-cancel/components/service-cancellation-process/style.ts'
import Button from '@components/ui/button-legacy/Button.tsx'
import {useServiceCancellationStore} from '@/features/service-cancel/store/serviceCancellationStore.tsx'
import {ServiceValueProps} from '@/features/in-progress-services/utils.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useMemo} from 'react'
import {getServiceType} from '@utilities/helpers.ts'
import {cancelStepsConfigByService} from '@/features/service-cancel/utils.ts'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {breakpoints} from '@/theme/mediaQueries.ts'

export const CancelServiceWarningStep = () => {
    const {t} = useTranslation()
    const cancelServiceModalToggle = useServiceCancellationStore(state => state.cancellationServiceModalToggle)
    const changeDeclineModalStep = useServiceCancellationStore(state => state.changeStep)
    const service = useServiceCancellationStore(state => state.service)
    const serviceType = useMemo(
        () => (service ? getServiceType(service.task_media.name, service.task_category.name) : 'home'),
        [service]
    )
    const {width: viewportWidth} = useWindowDimensions()

    return (
        <>
            <StyledCancelStepHeader>
                {t(cancelStepsConfigByService['warning'][serviceType].modal_title)}
            </StyledCancelStepHeader>

            <Flexbox direction={'column'} gap={6} padding={6}>
                <StyledCancelStepDesc direction={'column'} gap={2}>
                    <h5>{t(cancelStepsConfigByService['warning'][serviceType].title)}</h5>
                    <small>{t(cancelStepsConfigByService['warning'][serviceType].subtitle)}</small>
                </StyledCancelStepDesc>
                {cancelStepsConfigByService['warning'][serviceType].stats && (
                    <Flexbox gap={2} justify={'space-between'} width="100%">
                        {Object.values(ServiceValueProps[serviceType])
                            .slice(0, 2)
                            .map((statsItem, index) => (
                                <StyledCancelStatsWidgetWrapper direction={'column'} gap={1} key={index}>
                                    <small>{t(statsItem.labelText)}</small>
                                    <h4>{t(statsItem.data)}</h4>
                                    <p>
                                        {t(statsItem.subtext)}
                                        {serviceType == 'home' && <sup>1</sup>}
                                    </p>
                                </StyledCancelStatsWidgetWrapper>
                            ))}
                    </Flexbox>
                )}
                {serviceType == 'home' && (
                    <StyledCancelDisclaimer>
                        {t('service_cancel:warning:home:stats_disclaimer_paragraph')}{' '}
                        <Tooltip
                            maxWidth="320px"
                            side={viewportWidth <= breakpoints.width.m ? undefined : 'right'}
                            trigger={<span>{t('commons:view_more')}</span>}
                            content={
                                <StyledCancelDisclaimerTooltip direction="column" gap={4}>
                                    <p>{t('service_cancel:warning:home:stats_disclaimer_tooltip1')}</p>
                                    <p>{t('service_cancel:warning:home:stats_disclaimer_tooltip2')}</p>
                                </StyledCancelDisclaimerTooltip>
                            }
                        />
                    </StyledCancelDisclaimer>
                )}
            </Flexbox>

            <StyledCancelStepFooter justify={'space-between'}>
                <Button variant={'tertiary'} size={'md'} onClick={() => cancelServiceModalToggle()}>
                    {t(cancelStepsConfigByService['warning'][serviceType].secondary_cta)}
                </Button>
                <Button variant={'primary'} size={'md'} onClick={() => changeDeclineModalStep('final')}>
                    {t(cancelStepsConfigByService['warning'][serviceType].primary_cta)}
                </Button>
            </StyledCancelStepFooter>
        </>
    )
}

CancelServiceWarningStep.displayName = 'CancelServiceWarningStep'
