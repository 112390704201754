import {ComboboxProvider, ComboboxItemCheck} from '@ariakit/react'
import {FC, useRef, useState} from 'react'
import {ComboboxItem, ComboboxList, ComboboxPopover} from '@components/ui/select-atoms/SelectAtoms.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {CheckIcon, ChevronDownIcon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTheme} from 'styled-components'
import {StyledComboboxInput} from '@/features/experiences-video/components/location-select/style.ts'

interface LocationSelectProps {
    defaultValue?: string
    value?: string
    options: string[]
    onSelectOption: (option: string) => void
}
export const LocationSelect: FC<LocationSelectProps> = ({defaultValue, options, onSelectOption, value}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [inputValue, setInputValue] = useState(value)
    const inputWrapperRef = useRef<HTMLDivElement>(null)
    const selectOptions = [...(value && !options.includes(value) ? [value] : []), ...options]

    return (
        <ComboboxProvider
            placement="top"
            defaultValue={defaultValue}
            value={value}
            setValue={value => {
                onSelectOption(value)
                setInputValue(value)
            }}
        >
            {/*This div is needed to anchor the popover to the input wrapper*/}
            <div ref={inputWrapperRef}>
                <StyledComboboxInput
                    placeholder={t('experiences_video:location_select_placeholder')}
                    endIcon={<ChevronDownIcon />}
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                    setValueOnChange={false}
                />
            </div>
            <ComboboxPopover
                flip={false}
                getAnchorRect={() => inputWrapperRef.current?.getBoundingClientRect() ?? null}
            >
                <ComboboxList>
                    {selectOptions.map(option => (
                        <ComboboxItem
                            value={option}
                            key={option}
                            render={
                                <Flexbox justify="space-between" align="center">
                                    {option}
                                    <ComboboxItemCheck checked={value ? value == option : undefined}>
                                        <CheckIcon size={20} fill={theme.palette.primary['600']} />
                                    </ComboboxItemCheck>
                                </Flexbox>
                            }
                        ></ComboboxItem>
                    ))}
                    {inputValue && !selectOptions.includes(inputValue) && (
                        <ComboboxItem value={inputValue}>
                            <Trans
                                i18nKey="experiences_video:location_select_add_option"
                                values={{inputValue}}
                                components={[<b />]}
                            />
                        </ComboboxItem>
                    )}
                </ComboboxList>
            </ComboboxPopover>
        </ComboboxProvider>
    )
}
