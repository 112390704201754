import {
    ExperiencesVideoFooter,
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import filmProps from '@assets/images/experiences-video/film_props.svg'
import {
    StyledImage,
    StyledBody,
    StyledStepTitle
} from '@/features/experiences-video/components/preparation-intro-step/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {ExperiencesVideoProgressbar} from '@/features/experiences-video/components/experiences-video-progressbar/ExperiencesVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    useExperiencesVideoStore,
    useExperiencesVideoStoreValues
} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {useUpdateExperiencesVideo} from '@/features/experiences-video/services/useUpdateExperiencesVideo.ts'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import toast from 'react-hot-toast'

export const PreparationIntroStep = () => {
    const {t} = useTranslation()
    const urlParams = useExperiencesVideoUrlParams()
    const setStep = useExperiencesVideoStore(store => store.setStep)
    const storeValues = useExperiencesVideoStoreValues()
    const backExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('intro'),
            onError: () => toast.error(t('errors:default'))
        }
    })
    const continueExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('preparation_initial_location'),
            onError: () => toast.error(t('errors:default'))
        }
    })

    return (
        <>
            <StyledBody>
                <StyledImage src={filmProps} alt="step image" />
                <Flexbox direction="column" gap={4}>
                    <StyledStepTitle>{t('experiences_video:step_x', {stepNumber: 1})}</StyledStepTitle>
                    <ExperiencesVideoTitle>{t('experiences_video:preparation')}</ExperiencesVideoTitle>
                    <ExperiencesVideoParagraph>
                        {t('experiences_video:preparation_intro:description')}
                    </ExperiencesVideoParagraph>
                </Flexbox>
            </StyledBody>
            <ExperiencesVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                            variant="tertiary"
                            onClick={() => backExperiencesVideoMutation.mutate({data: {...storeValues, step: 'intro'}})}
                        >
                            {t('commons:back')}
                            {backExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            onClick={() =>
                                continueExperiencesVideoMutation.mutate({
                                    data: {...storeValues, step: 'preparation_initial_location'}
                                })
                            }
                            disabled={
                                continueExperiencesVideoMutation.isPending || backExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:continue')}
                            {continueExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>
        </>
    )
}
