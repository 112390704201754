import {
    QuizModalAnswerResponse,
    QuizModalFooter,
    QuizModalQuestion,
    QuizModalQuestionHint,
    QuizItemLabelContainer
} from '@/features/experiences-video/components/quiz-modal-atoms/QuizModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    ExperiencesVideoQuizStore,
    useExperiencesVideoQuizStore
} from '@/features/experiences-video/stores/experiencesVideoQuiz.ts'
import {ExperiencesVideoAnswerItem} from '@/features/experiences-video/components/experiences-video-answer-item/ExperiencesVideoAnswerItem.tsx'
import {handleCloseSlidingModal, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {CheckCircleBrokenIcon, XCircleIcon} from '@components/ui/icon'
import {httpCompleteExperiencesVideoQuiz} from '@/features/experiences-video/services/experiencesVideo.http.ts'
import toast from 'react-hot-toast'
import {useAsync} from '@hooks/useAsync.ts'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {ExperiencesVideoModalBody} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

const optionToTranslationKey = {
    combination: 'experiences_video:host_friend_get_ready:quiz:elements:combination',
    do_not_move: 'experiences_video:host_friend_get_ready:quiz:elements:do_not_move',
    do_not_zoom: 'experiences_video:host_friend_get_ready:quiz:elements:do_not_zoom',
    all: 'experiences_video:host_friend_get_ready:quiz:elements:all'
} as const satisfies Record<NonNullable<ExperiencesVideoQuizStore['elementsAnswer']>, string>

export const QuizModalElementsStep = ({
    setSlidingModalState
}: {
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}) => {
    const {t} = useTranslation()
    const urlParams = useExperiencesVideoUrlParams()
    const setElementsAnswer = useExperiencesVideoQuizStore(store => store.setElementsAnswer)
    const elementsAnswer = useExperiencesVideoQuizStore(store => store.elementsAnswer)
    const step = useExperiencesVideoQuizStore(store => store.step)
    const phoneAnswer = useExperiencesVideoQuizStore(store => store.phoneAnswer)
    const uploadAnswer = useExperiencesVideoQuizStore(store => store.uploadAnswer)
    const holdCameraAnswer = useExperiencesVideoQuizStore(store => store.holdCameraAnswer)
    const stagedPeopleCountAnswer = useExperiencesVideoQuizStore(store => store.stagedPeopleCountAnswer)
    const userType = useExperiencesVideoQuizStore(store => store.userType)
    const completeQuiz = useAsync()

    const onComplete = async () => {
        try {
            await completeQuiz.run(
                httpCompleteExperiencesVideoQuiz({
                    urlParams,
                    payload: {
                        user_type: userType,
                        data: {
                            step,
                            phoneAnswer,
                            uploadAnswer,
                            holdCameraAnswer,
                            stagedPeopleCountAnswer,
                            elementsAnswer
                        }
                    }
                })
            )
            handleCloseSlidingModal(setSlidingModalState)
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <>
            <ExperiencesVideoModalBody>
                <Flexbox direction="column" gap={2}>
                    <QuizModalQuestion>
                        {t('experiences_video:host_friend_get_ready:quiz:elements:question')}
                    </QuizModalQuestion>
                    <QuizModalQuestionHint>
                        {t('experiences_video:host_friend_get_ready:quiz:tap_options')}
                    </QuizModalQuestionHint>
                </Flexbox>
                <Flexbox direction="column" gap={2} width="100%" align="stretch">
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer>
                                {t(optionToTranslationKey['combination'])}
                                {elementsAnswer == 'combination' && <XCircleIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="combination"
                        name="option"
                        onChange={() => setElementsAnswer('combination')}
                        checked={elementsAnswer == 'combination'}
                        disabled={!!elementsAnswer && elementsAnswer != 'combination'}
                    />
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer>
                                {t(optionToTranslationKey['do_not_move'])}
                                {elementsAnswer == 'do_not_move' && <XCircleIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="do_not_move"
                        name="option"
                        onChange={() => setElementsAnswer('do_not_move')}
                        checked={elementsAnswer == 'do_not_move'}
                        disabled={!!elementsAnswer && elementsAnswer != 'do_not_move'}
                    />
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer>
                                {t(optionToTranslationKey['do_not_zoom'])}
                                {elementsAnswer == 'do_not_zoom' && <XCircleIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="do_not_zoom"
                        name="option"
                        onChange={() => setElementsAnswer('do_not_zoom')}
                        checked={elementsAnswer == 'do_not_zoom'}
                        disabled={!!elementsAnswer && elementsAnswer != 'do_not_zoom'}
                    />
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect>
                                {t(optionToTranslationKey['all'])}
                                {elementsAnswer == 'all' && <CheckCircleBrokenIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="all"
                        name="option"
                        onChange={() => setElementsAnswer('all')}
                        checked={elementsAnswer == 'all'}
                        disabled={!!elementsAnswer && elementsAnswer != 'all'}
                    />
                </Flexbox>
                {elementsAnswer && (
                    <QuizModalAnswerResponse
                        variant={elementsAnswer == 'all' ? 'success' : 'danger'}
                        title={t(
                            `experiences_video:host_friend_get_ready:quiz:${
                                elementsAnswer == 'all' ? 'answer_correct' : 'answer_incorrect'
                            }`
                        )}
                        paragraph={t('experiences_video:host_friend_get_ready:quiz:elements:description')}
                    />
                )}
            </ExperiencesVideoModalBody>
            <QuizModalFooter progressPercentages={[100, 100, 100, 100, 100]}>
                <Button disabled={!elementsAnswer || completeQuiz.isLoading} onClick={onComplete}>
                    {t('commons:done')}
                    {completeQuiz.isLoading && <Spinner />}
                </Button>
            </QuizModalFooter>
        </>
    )
}
