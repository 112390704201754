import {forwardRef, InputHTMLAttributes, ReactNode} from 'react'
import {
    StyledExperiencesVideoAnswerInput,
    StyledExperiencesVideoAnswerContainer
} from '@/features/experiences-video/components/experiences-video-answer-item/style.ts'

export interface ExperiencesVideoAnswerItemProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    className?: string
    label: ReactNode
    type: 'radio' | 'checkbox'
}
export const ExperiencesVideoAnswerItem = forwardRef<HTMLInputElement, ExperiencesVideoAnswerItemProps>(
    ({className, label, ...rest}, ref) => {
        return (
            <StyledExperiencesVideoAnswerContainer $isReadonly={!!rest.readOnly} className={className}>
                {label}
                <StyledExperiencesVideoAnswerInput ref={ref} {...rest} />
            </StyledExperiencesVideoAnswerContainer>
        )
    }
)
