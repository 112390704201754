import {
    StyledCheckbox,
    StyledRecapLocationCard
} from '@/features/experiences-video/components/recap-location-card/style.ts'
import {forwardRef} from 'react'
import {CheckIcon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesVideoAnswerItemProps} from '@/features/experiences-video/components/experiences-video-answer-item/ExperiencesVideoAnswerItem.tsx'

export const RecapLocationCard = forwardRef<HTMLInputElement, Omit<ExperiencesVideoAnswerItemProps, 'type' | 'label'>>(
    ({children, readOnly, ...rest}, ref) => {
        return (
            <StyledRecapLocationCard
                $isReadonly={!!readOnly}
                type="checkbox"
                label={
                    <>
                        <Flexbox direction="column" gap={2}>
                            {children}
                        </Flexbox>
                        <StyledCheckbox className="location-card-checkbox">
                            <CheckIcon size={14} />
                        </StyledCheckbox>
                    </>
                }
                readOnly={readOnly}
                {...rest}
                {...(readOnly && {checked: false})}
                ref={ref}
            />
        )
    }
)
