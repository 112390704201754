import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {stepToProgress} from '@/features/experiences-video/utils.tsx'
import {useExperiencesVideoStore} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {ProgressBar} from '@/features/experiences-video/components/progress-bar/ProgressBar.tsx'

export const ExperiencesVideoProgressbar = () => {
    const step = useExperiencesVideoStore(state => state.step)

    return (
        <Flexbox width="100%" gap={1}>
            {stepToProgress(step).map((barPercentage, index) => (
                <ProgressBar key={index} percentage={barPercentage} />
            ))}
        </Flexbox>
    )
}
