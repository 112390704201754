import styled, {css} from 'styled-components'

export const StyledRequirementCard = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
        gap: ${spacing * 3}px;
        & h3 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & p {
            ${typography.textSm};
            color: ${palette.neutral['700']};
        }
    `
)

export const StyledIconWrapper = styled.div(
    ({theme: {palette}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${palette.neutral['100']};
        color: ${palette.neutral['900']};
        width: 38px;
        height: 38px;
        border-radius: 50%;
        & svg {
            width: 20px;
            height: 20px;
        }
    `
)
