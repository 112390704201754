import {
    QuizModalAnswerResponse,
    QuizModalFooter,
    QuizModalQuestion,
    QuizModalQuestionHint,
    QuizItemLabelContainer
} from '@/features/experiences-video/components/quiz-modal-atoms/QuizModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesVideoAnswerItem} from '@/features/experiences-video/components/experiences-video-answer-item/ExperiencesVideoAnswerItem.tsx'
import {
    ExperiencesVideoQuizStore,
    useExperiencesVideoQuizStore
} from '@/features/experiences-video/stores/experiencesVideoQuiz.ts'
import {CheckCircleBrokenIcon, XCircleIcon} from '@components/ui/icon'
import {ExperiencesVideoModalBody} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

const optionToTranslationKey = {
    '1_edited_video': 'experiences_video:host_friend_get_ready:quiz:upload:1_edited_video',
    '20_40_short_clips': 'experiences_video:host_friend_get_ready:quiz:upload:20_40_short_clips',
    '5_photos': 'experiences_video:host_friend_get_ready:quiz:upload:5_photos'
} as const satisfies Record<NonNullable<ExperiencesVideoQuizStore['uploadAnswer']>, string>

export const QuizModalUploadStep = () => {
    const {t} = useTranslation()

    const setUploadAnswer = useExperiencesVideoQuizStore(store => store.setUploadAnswer)
    const setStep = useExperiencesVideoQuizStore(store => store.setStep)
    const uploadAnswer = useExperiencesVideoQuizStore(store => store.uploadAnswer)

    return (
        <>
            <ExperiencesVideoModalBody>
                <Flexbox direction="column" gap={2}>
                    <QuizModalQuestion>
                        {t('experiences_video:host_friend_get_ready:quiz:upload:question')}
                    </QuizModalQuestion>
                    <QuizModalQuestionHint>
                        {t('experiences_video:host_friend_get_ready:quiz:tap_option')}
                    </QuizModalQuestionHint>
                </Flexbox>
                <Flexbox direction="column" gap={2} width="100%" align="stretch">
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer>
                                {t(optionToTranslationKey['1_edited_video'])}
                                {uploadAnswer == '1_edited_video' && <XCircleIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="radio"
                        value="1_edited_video"
                        name="option"
                        onChange={() => setUploadAnswer('1_edited_video')}
                        checked={uploadAnswer == '1_edited_video'}
                        disabled={!!uploadAnswer && uploadAnswer != '1_edited_video'}
                    />
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect>
                                {t(optionToTranslationKey['20_40_short_clips'])}
                                {uploadAnswer == '20_40_short_clips' && <CheckCircleBrokenIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="radio"
                        value="20_40_short_clips"
                        name="option"
                        onChange={() => setUploadAnswer('20_40_short_clips')}
                        checked={uploadAnswer == '20_40_short_clips'}
                        disabled={!!uploadAnswer && uploadAnswer != '20_40_short_clips'}
                    />
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer>
                                {t(optionToTranslationKey['5_photos'])}
                                {uploadAnswer == '5_photos' && <XCircleIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="radio"
                        value="5_photos"
                        name="option"
                        onChange={() => setUploadAnswer('5_photos')}
                        checked={uploadAnswer == '5_photos'}
                        disabled={!!uploadAnswer && uploadAnswer != '5_photos'}
                    />
                </Flexbox>
                {uploadAnswer && (
                    <QuizModalAnswerResponse
                        variant={uploadAnswer == '20_40_short_clips' ? 'success' : 'danger'}
                        title={t(
                            `experiences_video:host_friend_get_ready:quiz:${
                                uploadAnswer == '20_40_short_clips' ? 'answer_correct' : 'answer_incorrect'
                            }`
                        )}
                        paragraph={t('experiences_video:host_friend_get_ready:quiz:upload:description')}
                    />
                )}
            </ExperiencesVideoModalBody>
            <QuizModalFooter progressPercentages={[100, 100, 0, 0, 0]}>
                <Button disabled={!uploadAnswer} onClick={() => setStep('hold_camera')}>
                    {t('experiences_video:host_friend_get_ready:quiz:next_question')}
                </Button>
            </QuizModalFooter>
        </>
    )
}
