import {
    ExperiencesVideoBody,
    ExperiencesVideoFooter,
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle,
    ShortClipExampleButton
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {PlayIcon, PlusIcon, Trash03Icon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    useExperiencesVideoStore,
    useExperiencesVideoStoreValues
} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {ExperiencesVideoProgressbar} from '@/features/experiences-video/components/experiences-video-progressbar/ExperiencesVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ShortClipModal} from '@/features/experiences-video/components/short-clip-modal/ShortClipModal.tsx'
import {Label} from '@components/ui/label/Label.tsx'
import {LocationSelect} from '@/features/experiences-video/components/location-select/LocationSelect.tsx'
import {raise} from '@utilities/helpers.ts'
import {z} from 'zod'
import {Controller, useFieldArray, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import i18n from '@/translations/i18n.ts'
import {ErrorMessage} from '@components/ui/error-message/ErrorMessage.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {
    StyledInteractionFieldLabel,
    StyledInteractionInput
} from '@/features/experiences-video/components/preparation-interactions-step/style.ts'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useUpdateExperiencesVideo} from '@/features/experiences-video/services/useUpdateExperiencesVideo.ts'
import toast from 'react-hot-toast'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'

const ValidationSchema = z
    .object({
        do_not_interact: z.literal(true),
        interactionsLocation: z.string().min(1, {message: i18n.t('experiences_video:location_is_required')}),
        interactions: z.array(z.object({value: z.string()}))
    })
    .or(
        z.object({
            do_not_interact: z.literal(false),
            interactionsLocation: z.string().min(1, {message: i18n.t('experiences_video:location_is_required')}),
            interactions: z
                .array(
                    z.object({
                        value: z.string().min(1, {
                            message: i18n.t('experiences_video:preparation_interactions:interactions_field_required')
                        })
                    })
                )
                .min(1, {message: i18n.t('experiences_video:preparation_interactions:interactions_required')})
        })
    )
type ValidationSchema = z.infer<typeof ValidationSchema>

export const PreparationInteractionsStep = () => {
    const {t} = useTranslation()
    const doNotInteract = useExperiencesVideoStore(store => store.doNotInteract)
    const setDoNotInteract = useExperiencesVideoStore(store => store.setDoNotInteract)
    const initialLocation =
        useExperiencesVideoStore(store => store.initialLocation) ?? raise('initialLocation is nullish')
    const expertisesLocation =
        useExperiencesVideoStore(store => store.expertisesLocation) ?? raise('expertisesLocation is nullish')
    const setInteractionsLocation = useExperiencesVideoStore(store => store.setInteractionsLocation)
    const interactionsLocation = useExperiencesVideoStore(store => store.interactionsLocation)
    const interactions = useExperiencesVideoStore(store => store.interactions)
    const setInteractions = useExperiencesVideoStore(store => store.setInteractions)
    const setStep = useExperiencesVideoStore(store => store.setStep)
    const urlParams = useExperiencesVideoUrlParams()
    const storeValues = useExperiencesVideoStoreValues()
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const backExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('preparation_expertises'),
            onError: () => toast.error(t('errors:default'))
        }
    })
    const continueExperiencesVideoMutation = useUpdateExperiencesVideo({urlParams})
    const form = useForm<ValidationSchema>({
        defaultValues: {
            do_not_interact: doNotInteract,
            interactions:
                interactions.length == 0 ? [{value: ''}] : interactions.map(interaction => ({value: interaction})),
            interactionsLocation: interactionsLocation ?? expertisesLocation
        },
        resolver: zodResolver(ValidationSchema)
    })
    const fieldArray = useFieldArray({
        control: form.control,
        name: 'interactions'
    })

    const doNotInteractWatch = useWatch({name: 'do_not_interact', control: form.control})

    const onContinue = form.handleSubmit(async formValues => {
        try {
            await continueExperiencesVideoMutation.mutateAsync({
                data: {
                    ...storeValues,
                    step: 'preparation_emotions',
                    doNotInteract: formValues.do_not_interact,
                    interactionsLocation: formValues.interactionsLocation,
                    interactions: formValues.do_not_interact
                        ? []
                        : formValues.interactions.map(interaction => interaction.value)
                }
            })
            setInteractionsLocation(formValues.interactionsLocation)
            setDoNotInteract(formValues.do_not_interact)
            setStep('preparation_emotions')
            if (!formValues.do_not_interact) {
                setInteractions(formValues.interactions.map(interaction => interaction.value))
            }
        } catch (error) {
            toast.error(t('errors:default'))
        }
    })

    return (
        <>
            <ExperiencesVideoBody>
                <Flexbox direction="column" gap={4}>
                    <ExperiencesVideoTitle>
                        {t('experiences_video:preparation_interactions:title')}
                    </ExperiencesVideoTitle>
                    <ExperiencesVideoParagraph>
                        {t('experiences_video:preparation_interactions:description')}
                    </ExperiencesVideoParagraph>
                    <ShortClipExampleButton onClick={() => setSlidingModalState('open')}>
                        <PlayIcon />
                        {t('experiences_video:short_clip_example')}
                    </ShortClipExampleButton>
                </Flexbox>
                <Flexbox direction="column" gap={6} width="100%">
                    <Flexbox direction="column" gap={4} width="100%">
                        {fieldArray.fields.map((field, index) => (
                            <Flexbox direction="column" gap={2} width="100%" key={field.id}>
                                <StyledInteractionFieldLabel>
                                    {t('experiences_video:preparation_interactions:interaction')}
                                </StyledInteractionFieldLabel>
                                <Flexbox gap={2} width="100%">
                                    <StyledInteractionInput
                                        disabled={doNotInteractWatch}
                                        inputSize="sm"
                                        type="text"
                                        placeholder={t(
                                            'experiences_video:preparation_interactions:interaction_field_placeholder'
                                        )}
                                        {...form.register(`interactions.${index}.value`)}
                                    />
                                    {fieldArray.fields.length >= 2 && (
                                        <Button
                                            disabled={doNotInteractWatch}
                                            fullWidth
                                            shape="square"
                                            variant="secondary"
                                            onClick={() => fieldArray.remove(index)}
                                        >
                                            <Trash03Icon />
                                        </Button>
                                    )}
                                </Flexbox>
                                {!!form.formState.errors.interactions?.[index]?.value?.message && (
                                    <ErrorMessage>
                                        {form.formState.errors.interactions[index]?.value?.message}
                                    </ErrorMessage>
                                )}
                            </Flexbox>
                        ))}
                        <Button
                            disabled={doNotInteractWatch}
                            fullWidth
                            size="sm"
                            variant="secondary"
                            onClick={() => fieldArray.append({value: ''})}
                        >
                            <PlusIcon />
                            {t('experiences_video:preparation_interactions:add_interaction')}
                        </Button>
                    </Flexbox>
                    <Checkbox
                        id="do_not_interact"
                        label={t('experiences_video:preparation_interactions:do_not_interact_label')}
                        {...form.register('do_not_interact')}
                    />
                </Flexbox>
                <Flexbox direction="column" gap={1.5} width="100%" align="stretch">
                    <Label>{t('experiences_video:preparation_interactions:location_select_label')}</Label>
                    <Controller
                        control={form.control}
                        name="interactionsLocation"
                        render={({field}) => (
                            <LocationSelect
                                value={field.value}
                                //set needed because the two locations can be the same
                                options={[...new Set([expertisesLocation, initialLocation])]}
                                onSelectOption={field.onChange}
                            />
                        )}
                    />
                    {!!form.formState.errors.interactionsLocation && (
                        <ErrorMessage>{form.formState.errors.interactionsLocation.message}</ErrorMessage>
                    )}
                </Flexbox>
            </ExperiencesVideoBody>
            <ExperiencesVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            variant="tertiary"
                            onClick={() =>
                                backExperiencesVideoMutation.mutate({
                                    data: {...storeValues, step: 'preparation_expertises'}
                                })
                            }
                            disabled={
                                continueExperiencesVideoMutation.isPending || backExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:back')}
                            {backExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onContinue}
                            disabled={
                                continueExperiencesVideoMutation.isPending || backExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:continue')}
                            {continueExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>

            {slidingModalState != 'closed' && (
                <ShortClipModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    src={'https://videos.pexels.com/video-files/27878573/12253181_1440_2560_25fps.mp4'}
                />
            )}
        </>
    )
}
