import {
    QuizModalAnswerResponse,
    QuizModalFooter,
    QuizModalQuestion,
    QuizModalQuestionHint,
    QuizItemLabelContainer
} from '@/features/experiences-video/components/quiz-modal-atoms/QuizModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesVideoAnswerItem} from '@/features/experiences-video/components/experiences-video-answer-item/ExperiencesVideoAnswerItem.tsx'
import {
    ExperiencesVideoQuizStore,
    useExperiencesVideoQuizStore
} from '@/features/experiences-video/stores/experiencesVideoQuiz.ts'
import {CheckCircleBrokenIcon, XCircleIcon} from '@components/ui/icon'
import {ExperiencesVideoModalBody} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

const optionToTranslationKey = {
    none: 'experiences_video:host_friend_get_ready:quiz:staged_people_count:none',
    at_least_3: 'experiences_video:host_friend_get_ready:quiz:staged_people_count:at_least_3'
} as const satisfies Record<NonNullable<ExperiencesVideoQuizStore['stagedPeopleCountAnswer']>, string>

export const QuizModalStagedPeopleCountStep = () => {
    const {t} = useTranslation()
    const setStagedPeopleCountAnswer = useExperiencesVideoQuizStore(store => store.setStagedPeopleCountAnswer)
    const setStep = useExperiencesVideoQuizStore(store => store.setStep)
    const stagedPeopleCountAnswer = useExperiencesVideoQuizStore(store => store.stagedPeopleCountAnswer)
    return (
        <>
            <ExperiencesVideoModalBody>
                <Flexbox direction="column" gap={2}>
                    <QuizModalQuestion>
                        {t('experiences_video:host_friend_get_ready:quiz:staged_people_count:question')}
                    </QuizModalQuestion>
                    <QuizModalQuestionHint>
                        {t('experiences_video:host_friend_get_ready:quiz:tap_options')}
                    </QuizModalQuestionHint>
                </Flexbox>
                <Flexbox direction="column" gap={2} width="100%" align="stretch">
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer>
                                {t(optionToTranslationKey['none'])}
                                {stagedPeopleCountAnswer == 'none' && <XCircleIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="none"
                        name="option"
                        onChange={() => setStagedPeopleCountAnswer('none')}
                        checked={stagedPeopleCountAnswer == 'none'}
                        disabled={!!stagedPeopleCountAnswer && stagedPeopleCountAnswer != 'none'}
                    />
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect>
                                {t(optionToTranslationKey['at_least_3'])}
                                {stagedPeopleCountAnswer == 'at_least_3' && <CheckCircleBrokenIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="at_least_3"
                        name="option"
                        onChange={() => setStagedPeopleCountAnswer('at_least_3')}
                        checked={stagedPeopleCountAnswer == 'at_least_3'}
                        disabled={!!stagedPeopleCountAnswer && stagedPeopleCountAnswer != 'at_least_3'}
                    />
                </Flexbox>
                {stagedPeopleCountAnswer && (
                    <QuizModalAnswerResponse
                        variant={stagedPeopleCountAnswer == 'at_least_3' ? 'success' : 'danger'}
                        title={t(
                            `experiences_video:host_friend_get_ready:quiz:${
                                stagedPeopleCountAnswer == 'at_least_3' ? 'answer_correct' : 'answer_incorrect'
                            }`
                        )}
                        paragraph={t('experiences_video:host_friend_get_ready:quiz:staged_people_count:description')}
                    />
                )}
            </ExperiencesVideoModalBody>
            <QuizModalFooter progressPercentages={[100, 100, 100, 100, 0]}>
                <Button disabled={!stagedPeopleCountAnswer} onClick={() => setStep('elements')}>
                    {t('experiences_video:host_friend_get_ready:quiz:next_question')}
                </Button>
            </QuizModalFooter>
        </>
    )
}
