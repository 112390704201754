import {ExperiencesVideoPageLayout} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {ExperiencesVideoHeader} from '@/features/experiences-video/components/experiences-video-header/ExperiencesVideoHeader.tsx'
import {
    ExperiencesVideoHostFriendStore,
    useExperiencesVideoHostFriendStore
} from '@/features/experiences-video/stores/experiencesVideoHostFriend.ts'
import {ReactElement, useEffect, useState} from 'react'
import {HostFriendGetReadyStep} from '@/features/experiences-video/components/host-friend-get-ready-step/HostFriendGetReadyStep.tsx'
import {useExperiencesVideo} from '@/features/experiences-video/services/useExperiencesVideo.ts'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertTriangleIcon} from '@components/ui/icon'
import {useTranslation} from 'react-i18next'

export const ExperiencesVideoHostFriend = () => {
    const {t} = useTranslation()
    const urlParams = useExperiencesVideoUrlParams()
    const query = useExperiencesVideo(urlParams)
    const [isInitialized, setIsInitialized] = useState(false)
    const currentStep = useExperiencesVideoHostFriendStore(state => state.step)
    const setShootList = useExperiencesVideoHostFriendStore(state => state.setShootList)
    const stepToComponent = {
        get_ready: <HostFriendGetReadyStep />
    } as const satisfies Record<ExperiencesVideoHostFriendStore['step'], ReactElement>

    useEffect(() => {
        setIsInitialized(false)
        const data = query.data?.data
        if (data) {
            setShootList({
                initialLocation: data.initialLocation,
                isInitialLocationChecked: false,
                expertises: data.expertises,
                expertisesLocation: data.expertisesLocation,
                isExpertisesChecked: false,
                interactions: data.interactions,
                interactionsLocation: data.interactionsLocation,
                isInteractionsChecked: false,
                emotions: data.emotions,
                emotionsLocation: data.emotionsLocation,
                isEmotionsChecked: false,
                dayTimes: data.dayTimes,
                venue: data.venue,
                dayTimesVenueLocation: data.dayTimesVenueLocation,
                isDayTimesVenueChecked: false
            })
            setIsInitialized(true)
        }
    }, [query.isSuccess])

    return (
        <ExperiencesVideoPageLayout>
            <ExperiencesVideoHeader />
            {query.isPending || !isInitialized ? (
                <Spinner size={32} style={{alignSelf: 'center'}} />
            ) : query.isError ? (
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:invalid_url')} />
            ) : (
                stepToComponent[currentStep]
            )}
        </ExperiencesVideoPageLayout>
    )
}
