import styled, {css} from 'styled-components'
import InputText from '@components/commons/input-text/InputText.tsx'

export const StyledInteractionFieldLabel = styled.label(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['700']};
    `
)

export const StyledInteractionInput = styled(InputText)`
    width: 100%;
`
