import styled, {css} from 'styled-components'
import {ExperiencesVideoBody} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

export const StyledImage = styled.img`
    align-self: center;
    width: 270px;
    height: 270px;
`

export const StyledBody = styled(ExperiencesVideoBody)(
    ({theme: {spacing}}) => css`
        gap: ${spacing * 9}px;
    `
)

export const StyledStepTitle = styled.h2(
    ({theme: {typography, palette}}) => css`
        color: ${palette.neutral['900']};
        ${typography.textMd};
        font-weight: 700;
    `
)
