import {useTranslation} from 'react-i18next'
import {Dispatch, FC, SetStateAction} from 'react'
import {handleCloseSlidingModal, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {
    ExperiencesVideoFooter,
    ExperiencesVideoModalBody
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ProgressBar} from '@/features/experiences-video/components/progress-bar/ProgressBar.tsx'
import {RequirementsModalStep} from '@/features/experiences-video/components/host-friend-requirements-modal/HostFriendRequirementsModal.tsx'
import {
    StyledHostFriendRequirementsModalShootlistStep,
    StyledTitle
} from '@/features/experiences-video/components/host-friend-requirements-modal-shootlist-step/style.ts'
import {LocationsRecap} from '@/features/experiences-video/components/locations-recap/LocationsRecap.tsx'
import {useExperiencesVideoHostFriendStore} from '@/features/experiences-video/stores/experiencesVideoHostFriend.ts'
import {raise} from '@utilities/helpers.ts'

interface HostFriendRequirementsModalShootListStepProps {
    setStep: Dispatch<SetStateAction<RequirementsModalStep>>
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const HostFriendRequirementsModalShootlistStep: FC<HostFriendRequirementsModalShootListStepProps> = ({
    setStep,
    setSlidingModalState
}) => {
    const {t} = useTranslation()
    const shootList = useExperiencesVideoHostFriendStore(store => store.shootList)
    const setIsRequirementsCompleted = useExperiencesVideoHostFriendStore(store => store.setIsRequirementsCompleted)

    return (
        <StyledHostFriendRequirementsModalShootlistStep>
            <StyledTitle>{t('experiences_video:host_friend_get_ready:requirements_modal:shootlist:title')}</StyledTitle>
            <ExperiencesVideoModalBody>
                <LocationsRecap
                    isReadonly
                    shootList={{...shootList, venue: shootList.venue ?? raise('venue is nullish')}}
                />
            </ExperiencesVideoModalBody>
            <ExperiencesVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <Flexbox width="100%" gap={1}>
                        <ProgressBar percentage={100} />
                        <ProgressBar percentage={100} />
                        <ProgressBar percentage={100} />
                    </Flexbox>
                    <Flexbox direction="column" gap={4.5} width="100%">
                        <Flexbox justify="space-between" width="100%">
                            <Button variant="tertiary" onClick={() => setStep('perspective')}>
                                {t('commons:back')}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    handleCloseSlidingModal(setSlidingModalState)
                                    setIsRequirementsCompleted(true)
                                }}
                            >
                                {t('commons:done')}
                            </Button>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>
        </StyledHostFriendRequirementsModalShootlistStep>
    )
}
