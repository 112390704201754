import {
    StyledExperiencesVideoActionCard,
    StyledExperiencesVideoBody,
    StyledExperiencesVideoContent,
    StyledExperiencesVideoFooter,
    StyledExperiencesVideoLabel,
    StyledExperiencesVideoModalBody,
    StyledExperiencesVideoPageLayout,
    StyledExperiencesVideoParagraph,
    StyledExperiencesVideoTitle,
    StyledShortClipExampleButton
} from '@/features/experiences-video/components/experiences-video-atoms/style.ts'
import {ButtonHTMLAttributes, HTMLAttributes} from 'react'
import {FlexBoxCustomProps} from '@components/ui/flexbox/FlexBox.tsx'
import {ButtonProps} from '@components/ui/button/Button.tsx'

export const ExperiencesVideoPageLayout = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledExperiencesVideoPageLayout {...props} />
)

export const ExperiencesVideoPageContent = (props: HTMLAttributes<HTMLDivElement>) => (
    <StyledExperiencesVideoContent {...props} />
)

export const ExperiencesVideoTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledExperiencesVideoTitle {...props} />
)

export const ExperiencesVideoParagraph = (props: HTMLAttributes<HTMLParagraphElement>) => (
    <StyledExperiencesVideoParagraph {...props} />
)

export const ExperiencesVideoBody = (props: FlexBoxCustomProps) => <StyledExperiencesVideoBody {...props} />

export const ExperiencesVideoModalBody = (props: FlexBoxCustomProps) => <StyledExperiencesVideoModalBody {...props} />

export const ExperiencesVideoFooter = (props: FlexBoxCustomProps) => <StyledExperiencesVideoFooter {...props} />

export const ShortClipExampleButton = (props: ButtonProps) => (
    <StyledShortClipExampleButton variant="secondary" size="sm" {...props} />
)

export const ExperiencesVideoLabel = (props: HTMLAttributes<HTMLLabelElement>) => (
    <StyledExperiencesVideoLabel {...props} />
)

export const ExperiencesVideoActionCard = ({
    isCompleted,
    ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {isCompleted?: boolean}) => (
    <StyledExperiencesVideoActionCard $isCompleted={isCompleted} {...props} />
)
