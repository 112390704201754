import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate, useOutletContext} from 'react-router-dom'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {HostExperienceProposal} from '@/features/experiences-video/types.ts'
import {
    StyledServicePromoCardBody,
    StyledServicePromoCardCtas,
    StyledServicePromoCardFooter,
    StyledServicePromoCardQuoteInfo,
    StyledServicePromoCardTitle,
    StyledServicePromoCardWrapper
} from '@/features/promotional-services/components/service-promo-card/style.ts'
import Divider from '@components/ui/divider/Divider.tsx'
import toast from 'react-hot-toast'
import {useAsync} from '@hooks/useAsync.ts'
import {httpStoreHostExperience} from '@/features/experiences-video/services/experiencesVideo.http.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {routes} from '@utilities/constants'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'

export const HostExpProposalsSectionCard: FC<{experience: HostExperienceProposal}> = ({experience}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const {urlParams} = useOutletContext<{urlParams: DashboardURLParams}>()
    const getStarted = useAsync()
    const navigate = useNavigate()

    const onCtaClick = async (experiencesVideoId: number, status: HostExperienceProposal['status']) => {
        try {
            if (status == 'pending') {
                await getStarted.run(httpStoreHostExperience({urlParams: {...urlParams, experiencesVideoId}}))
                await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.HOST_EXPERIENCE_PROPOSALS]})
            }
            navigate(
                generatePath(routes.EXPERIENCE_VIDEO.path, {
                    hostCode: urlParams.hostCode,
                    hostPassword: urlParams.hostPassword,
                    experiencesVideoId
                })
            )
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <StyledServicePromoCardWrapper>
            <StyledServicePromoCardBody direction={'column'}>
                <StyledServicePromoCardTitle direction={'column'}>
                    <a
                        href={undefined}
                        style={{height: 'auto'}}
                        title={experience.title ?? 'Experience trailer'}
                        target="_blank"
                    >
                        {experience.title ?? 'Experience trailer'}
                    </a>
                    <p>{experience.address}</p>
                </StyledServicePromoCardTitle>
            </StyledServicePromoCardBody>
            <Divider bottomSpacing={0} topSpacing={0} />
            <StyledServicePromoCardFooter align={'center'} justify={'space-between'}>
                <StyledServicePromoCardQuoteInfo direction={'column'}>
                    <h6>{t('commons:free')}</h6>
                </StyledServicePromoCardQuoteInfo>
                <StyledServicePromoCardCtas>
                    <Button
                        disabled={getStarted.isLoading}
                        onClick={() => onCtaClick(experience.id, experience.status)}
                        variant={'primary'}
                        size={'sm'}
                    >
                        {experience.status == 'in_progress'
                            ? t('experiences_video:resume')
                            : t('experiences_video:intro:cta')}
                        {getStarted.isLoading && <Spinner />}
                    </Button>
                </StyledServicePromoCardCtas>
            </StyledServicePromoCardFooter>
        </StyledServicePromoCardWrapper>
    )
}
