import {
    ExperiencesVideoBody,
    ExperiencesVideoFooter,
    ExperiencesVideoLabel,
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle,
    ShortClipExampleButton
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {PlayIcon} from '@components/ui/icon'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {ShortClipModal} from '@/features/experiences-video/components/short-clip-modal/ShortClipModal.tsx'
import {ExperiencesVideoProgressbar} from '@/features/experiences-video/components/experiences-video-progressbar/ExperiencesVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    useExperiencesVideoStore,
    useExperiencesVideoStoreValues
} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {z} from 'zod'
import i18n from '@/translations/i18n.ts'
import {ExperiencesVideoEmotions} from '@/features/experiences-video/types.ts'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {raise} from '@utilities/helpers.ts'
import {Label} from '@components/ui/label/Label.tsx'
import {LocationSelect} from '@/features/experiences-video/components/location-select/LocationSelect.tsx'
import {ErrorMessage} from '@components/ui/error-message/ErrorMessage.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {StyledEmotionsOptions} from '@/features/experiences-video/components/preparation-emotions-step/style.ts'
import {ExperiencesVideoAnswerItem} from '@/features/experiences-video/components/experiences-video-answer-item/ExperiencesVideoAnswerItem.tsx'
import {
    experienceVideoEmotionToIcon,
    experienceVideoEmotionToLabelTranslationKey
} from '@/features/experiences-video/utils.tsx'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useUpdateExperiencesVideo} from '@/features/experiences-video/services/useUpdateExperiencesVideo.ts'
import toast from 'react-hot-toast'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'

const ValidationSchema = z
    .object({
        not_sure: z.literal(true),
        emotionsLocation: z.string().min(1, {message: i18n.t('experiences_video:location_is_required')}),
        emotions: z.array(ExperiencesVideoEmotions)
    })
    .or(
        z.object({
            not_sure: z.literal(false),
            emotionsLocation: z.string().min(1, {message: i18n.t('experiences_video:location_is_required')}),
            emotions: z
                .array(ExperiencesVideoEmotions)
                .min(1, {message: i18n.t('experiences_video:min_options_error', {count: 1})})
                .max(3, {message: i18n.t('experiences_video:max_options_error', {count: 3})})
        })
    )
type ValidationSchema = z.infer<typeof ValidationSchema>

export const PreparationEmotionsStep = () => {
    const {t} = useTranslation()
    const setStep = useExperiencesVideoStore(store => store.setStep)
    const emotionsNotSure = useExperiencesVideoStore(store => store.emotionsNotSure)
    const setEmotionsNotSure = useExperiencesVideoStore(store => store.setEmotionsNotSure)
    const initialLocation =
        useExperiencesVideoStore(store => store.initialLocation) ?? raise('initialLocation is nullish')
    const expertisesLocation =
        useExperiencesVideoStore(store => store.expertisesLocation) ?? raise('expertiseLocation is nullish')
    const interactionsLocation =
        useExperiencesVideoStore(store => store.interactionsLocation) ?? raise('interactionsLocation is nullish')
    const emotionsLocation = useExperiencesVideoStore(store => store.emotionsLocation)
    const setEmotionsLocation = useExperiencesVideoStore(store => store.setEmotionsLocation)
    const emotions = useExperiencesVideoStore(store => store.emotions)
    const setEmotions = useExperiencesVideoStore(store => store.setEmotions)
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const form = useForm<ValidationSchema>({
        defaultValues: {
            emotions,
            emotionsLocation: emotionsLocation ?? interactionsLocation,
            not_sure: emotionsNotSure
        },
        resolver: zodResolver(ValidationSchema)
    })
    const emotionsWatch = useWatch({name: 'emotions', control: form.control})
    const urlParams = useExperiencesVideoUrlParams()
    const storeValues = useExperiencesVideoStoreValues()
    const backExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('preparation_interactions'),
            onError: () => toast.error(t('errors:default'))
        }
    })
    const continueExperiencesVideoMutation = useUpdateExperiencesVideo({urlParams})

    const notSureWatch = useWatch({name: 'not_sure', control: form.control})

    const onContinue = form.handleSubmit(async formValues => {
        try {
            await continueExperiencesVideoMutation.mutateAsync({
                data: {
                    ...storeValues,
                    step: 'preparation_daytimes_venue',
                    emotionsNotSure: formValues.not_sure,
                    emotionsLocation: formValues.emotionsLocation,
                    emotions: formValues.not_sure ? [] : formValues.emotions
                }
            })
            setEmotionsNotSure(formValues.not_sure)
            setEmotionsLocation(formValues.emotionsLocation)
            setStep('preparation_daytimes_venue')
            if (!formValues.not_sure) {
                setEmotions(formValues.emotions)
            }
        } catch (error) {
            toast.error(t('errors:default'))
        }
    })

    return (
        <>
            <ExperiencesVideoBody>
                <Flexbox direction="column" gap={4}>
                    <ExperiencesVideoTitle>{t('experiences_video:preparation_emotions:title')}</ExperiencesVideoTitle>
                    <ExperiencesVideoParagraph>
                        {t('experiences_video:preparation_emotions:description')}
                    </ExperiencesVideoParagraph>
                    <ShortClipExampleButton onClick={() => setSlidingModalState('open')}>
                        <PlayIcon />
                        {t('experiences_video:short_clip_example')}
                    </ShortClipExampleButton>
                </Flexbox>
                <Flexbox direction="column" gap={4} width="100%">
                    <ExperiencesVideoLabel>
                        {t('experiences_video:select_up_to_x_options', {count: 3})}
                    </ExperiencesVideoLabel>
                    <StyledEmotionsOptions>
                        {ExperiencesVideoEmotions.options.map(emotion => (
                            <ExperiencesVideoAnswerItem
                                {...form.register('emotions')}
                                disabled={
                                    notSureWatch || (emotionsWatch.length == 3 && !emotionsWatch.includes(emotion))
                                }
                                value={emotion}
                                type="checkbox"
                                label={
                                    <Flexbox direction="column" gap={2}>
                                        {experienceVideoEmotionToIcon[emotion]}
                                        {t(experienceVideoEmotionToLabelTranslationKey[emotion])}
                                    </Flexbox>
                                }
                                key={emotion}
                            />
                        ))}
                    </StyledEmotionsOptions>
                    <Checkbox
                        id="not_sure"
                        label={t('experiences_video:preparation_emotions:not_sure_label')}
                        {...form.register('not_sure')}
                    />
                    {!!form.formState.errors.emotions && (
                        <ErrorMessage>{form.formState.errors.emotions.message}</ErrorMessage>
                    )}
                </Flexbox>
                <Flexbox direction="column" gap={1.5} width="100%" align="stretch">
                    <Label>{t('experiences_video:preparation_emotions:location_select_label')}</Label>
                    <Controller
                        control={form.control}
                        name="emotionsLocation"
                        render={({field}) => (
                            <LocationSelect
                                value={field.value}
                                //set needed because the locations can be the duplicated
                                options={[...new Set([interactionsLocation, expertisesLocation, initialLocation])]}
                                onSelectOption={field.onChange}
                            />
                        )}
                    />
                    {!!form.formState.errors.emotionsLocation && (
                        <ErrorMessage>{form.formState.errors.emotionsLocation.message}</ErrorMessage>
                    )}
                </Flexbox>
            </ExperiencesVideoBody>
            <ExperiencesVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            variant="tertiary"
                            onClick={() =>
                                backExperiencesVideoMutation.mutate({
                                    data: {...storeValues, step: 'preparation_interactions'}
                                })
                            }
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:back')}
                            {backExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onContinue}
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:continue')}
                            {continueExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>

            {slidingModalState != 'closed' && (
                <ShortClipModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    src={'https://videos.pexels.com/video-files/27878573/12253181_1440_2560_25fps.mp4'}
                />
            )}
        </>
    )
}
