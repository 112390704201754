import {create} from 'zustand'
import {
    ExperiencesVideoDayTime,
    ExperiencesVideoEmotions,
    ExperiencesVideoExpertise,
    ExperiencesVideoVenue
} from '@/features/experiences-video/types.ts'

export interface ExperiencesVideoHostFriendStore {
    step: 'get_ready'
    shootList: {
        initialLocation: string | null
        isInitialLocationChecked: boolean
        expertises: ExperiencesVideoExpertise[]
        expertisesLocation: string | null
        isExpertisesChecked: boolean
        interactions: string[]
        interactionsLocation: string | null
        isInteractionsChecked: boolean
        emotions: ExperiencesVideoEmotions[]
        emotionsLocation: string | null
        isEmotionsChecked: boolean
        dayTimes: ExperiencesVideoDayTime[]
        venue: ExperiencesVideoVenue | null
        dayTimesVenueLocation: string | null
        isDayTimesVenueChecked: boolean
    }
    isRequirementsCompleted: boolean
    setStep: (step: ExperiencesVideoHostFriendStore['step']) => void
    setShootList: (shootList: ExperiencesVideoHostFriendStore['shootList']) => void
    setIsRequirementsCompleted: (isRequirementsCompleted: boolean) => void
}

export const useExperiencesVideoHostFriendStore = create<ExperiencesVideoHostFriendStore>()(set => ({
    step: 'get_ready',
    shootList: {
        initialLocation: null,
        isInitialLocationChecked: false,
        expertises: [],
        expertisesLocation: null,
        isExpertisesChecked: false,
        interactions: [],
        interactionsLocation: null,
        isInteractionsChecked: false,
        emotions: [],
        emotionsLocation: null,
        isEmotionsChecked: false,
        dayTimes: [],
        venue: null,
        dayTimesVenueLocation: null,
        isDayTimesVenueChecked: false
    },
    isRequirementsCompleted: false,
    setStep: step => set({step}),
    setShootList: shootList => set({shootList}),
    setIsRequirementsCompleted: isRequirementsCompleted => set({isRequirementsCompleted})
}))
