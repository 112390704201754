import {
    ExperiencesVideoBody,
    ExperiencesVideoFooter,
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesVideoProgressbar} from '@/features/experiences-video/components/experiences-video-progressbar/ExperiencesVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    useExperiencesVideoStore,
    useExperiencesVideoStoreValues
} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {useTranslation} from 'react-i18next'
import {raise} from '@utilities/helpers.ts'
import {LocationsRecap} from '@/features/experiences-video/components/locations-recap/LocationsRecap.tsx'
import {useUpdateExperiencesVideo} from '@/features/experiences-video/services/useUpdateExperiencesVideo.ts'
import toast from 'react-hot-toast'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'

export const PreparationRecapStep = () => {
    const {t} = useTranslation()
    const setStep = useExperiencesVideoStore(store => store.setStep)
    const initialLocation = useExperiencesVideoStore(store => store.initialLocation)
    const expertisesLocation = useExperiencesVideoStore(store => store.expertisesLocation)
    const interactionsLocation = useExperiencesVideoStore(store => store.interactionsLocation)
    const emotionsLocation = useExperiencesVideoStore(store => store.emotionsLocation)
    const dayTimesVenueLocation = useExperiencesVideoStore(store => store.dayTimesVenueLocation)
    const expertises = useExperiencesVideoStore(store => store.expertises)
    const interactions = useExperiencesVideoStore(store => store.interactions)
    const emotions = useExperiencesVideoStore(store => store.emotions)
    const dayTimes = useExperiencesVideoStore(store => store.dayTimes)
    const venue = useExperiencesVideoStore(store => store.venue) ?? raise('venue is nullish')
    const urlParams = useExperiencesVideoUrlParams()
    const storeValues = useExperiencesVideoStoreValues()
    const backExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('preparation_daytimes_venue'),
            onError: () => toast.error(t('errors:default'))
        }
    })
    const continueExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('preparation_requirements'),
            onError: () => toast.error(t('errors:default'))
        }
    })

    return (
        <>
            <ExperiencesVideoBody>
                <Flexbox direction="column" gap={4}>
                    <ExperiencesVideoTitle>{t('experiences_video:preparation_recap:title')}</ExperiencesVideoTitle>
                    <ExperiencesVideoParagraph>
                        {t('experiences_video:preparation_recap:description')}
                    </ExperiencesVideoParagraph>
                </Flexbox>
                <LocationsRecap
                    isReadonly
                    shootList={{
                        initialLocation,
                        isInitialLocationChecked: true,
                        expertises,
                        expertisesLocation,
                        isExpertisesChecked: true,
                        interactions,
                        interactionsLocation,
                        isInteractionsChecked: true,
                        emotions,
                        emotionsLocation,
                        isEmotionsChecked: true,
                        dayTimes,
                        venue,
                        dayTimesVenueLocation,
                        isDayTimesVenueChecked: true
                    }}
                />
            </ExperiencesVideoBody>
            <ExperiencesVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            variant="tertiary"
                            onClick={() =>
                                backExperiencesVideoMutation.mutate({
                                    data: {...storeValues, step: 'preparation_daytimes_venue'}
                                })
                            }
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:back')}
                            {backExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() =>
                                continueExperiencesVideoMutation.mutate({
                                    data: {...storeValues, step: 'preparation_requirements'}
                                })
                            }
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:continue')}
                            {continueExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>
        </>
    )
}
