import styled, {css} from 'styled-components'

export const StyledExperiencesVideoProgressbar = styled.div(
    ({theme: {palette}}) => css`
        height: 8px;
        background-color: ${palette.neutral[200]};
        width: 100%;
    `
)

export const ExperiencesVideoProgressbarIndicator = styled.div(
    ({theme: {palette}}) => css`
        height: 100%;
        background-color: ${palette.primary[600]};
    `
)
