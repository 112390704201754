import {useAuthStore} from '@/store/AuthStore'
import {useCoAlarmListingsTable} from '@/features/co-alarm/services/useCoAlarmListingsTable'
import {
    StyledCoAlarmListingsHeader,
    StyledCoAlarmListingsSearchLabel,
    StyledCoAlarmListingsSearchWrapper,
    StyledCoAlarmListingsWrapper
} from '@/features/co-alarm/components/co-alarm-dashboard-listings/style'
import InputText from '@/components/commons/input-text/InputText'
import {useTranslation} from 'react-i18next'
import {CoAlarmListingsTable} from '@/features/co-alarm/components/co-alarm-listings-table/CoAlarmListingsTable'
import {SearchLgIcon} from '@/components/ui/icon'
import {useRef} from 'react'
import {ButtonLink} from '@components/ui/button-link-legacy/ButtonLink.tsx'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {breakpoints} from '@/theme/mediaQueries'
import {CoAlarmListingsList} from '../co-alarm-listings-list/CoAlarmListingsList'

export const CoAlarmDashboardListings = () => {
    const {t} = useTranslation()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const user = useAuthStore(state => state.user)
    const {width} = useWindowDimensions()
    const isMobile = width < breakpoints.width.l

    //prevent strange behaviour
    if (!user) {
        throw t('error:default')
    }

    const {
        remappedData: listings,
        isLoading,
        isFetching,
        onSearch,
        isError,
        hasNextPage,
        fetchNextPage,
        searchValue,
        onResetSearch,
        isFetchingNextPage
    } = useCoAlarmListingsTable()

    return (
        <StyledCoAlarmListingsWrapper id="co-alarm-listings-section" direction="column" gap={8}>
            {((!isLoading && listings.length > 0) || searchValue) && (
                <StyledCoAlarmListingsHeader justify="space-between" align="center" width={'100%'}>
                    <StyledCoAlarmListingsSearchWrapper direction="column" gap={6}>
                        <StyledCoAlarmListingsSearchLabel htmlFor="search-listing">
                            {t('commons:your_active_listings')}
                        </StyledCoAlarmListingsSearchLabel>
                        <InputText
                            id="search-listing"
                            ref={searchRef}
                            isLoading={isFetching && !isFetchingNextPage}
                            typeIcon={<SearchLgIcon size={20} />}
                            inputSize="sm"
                            placeholder={t('commons:search_listing_placeholder')}
                            onChange={event => onSearch(event.target.value)}
                            type="text"
                            maxLength={50}
                        />
                    </StyledCoAlarmListingsSearchWrapper>
                    {!isMobile &&
                        (user?.co_alarm.status == 'eligible' || user?.co_alarm.status == 'installed') &&
                        user?.co_alarm.requests_count == 0 && (
                            <ButtonLink to={generatePath(routes.CO_ALARM_SEND_REQUEST.path)}>
                                {t('coAlarm:dashboard:cta')}
                            </ButtonLink>
                        )}
                </StyledCoAlarmListingsHeader>
            )}

            {isMobile ? (
                <CoAlarmListingsList
                    data={listings}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    hasNextPage={hasNextPage}
                    isError={isError}
                    isChangingPage={isFetchingNextPage}
                    searchValue={searchValue}
                    searchRef={searchRef}
                    onChangePage={fetchNextPage}
                    onResetSearch={onResetSearch}
                />
            ) : (
                <CoAlarmListingsTable
                    data={listings}
                    isLoading={isLoading}
                    isError={isError}
                    isChangingPage={isFetchingNextPage}
                    searchValue={searchValue}
                    searchRef={searchRef}
                    onChangePage={fetchNextPage}
                    onResetSearch={onResetSearch}
                />
            )}
        </StyledCoAlarmListingsWrapper>
    )
}
