import styled, {css} from 'styled-components'
import {ExperiencesVideoFooter} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

export const StyledQuizModalQuestion = styled.h2(
    ({theme: {typography, palette}}) => css`
        ${typography.textLg};
        color: ${palette.neutral['900']};
    `
)

export const StyledQuizModalQuestionHint = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['600']};
    `
)

export const StyledQuizModalAnswerResponse = styled.div<{$variant: 'success' | 'danger'}>(
    ({theme: {typography, palette, spacing}, $variant}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2.5}px;
        ${typography.textSm};
        padding: ${spacing * 3}px;
        border-width: 1px;
        border-style: solid;
        border-radius: 12px;

        & h3 {
            font-weight: 500;
        }

        ${$variant == 'success'
            ? css`
                  border-color: ${palette.success['300']};
                  background-color: ${palette.success['50']};
                  color: ${palette.success['700']};
              `
            : css`
                  border-color: ${palette.danger['300']};
                  background-color: ${palette.danger['50']};
                  color: ${palette.danger['700']};
              `}
    `
)

export const StyledQuizModalFooter = styled(ExperiencesVideoFooter)(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: ${spacing * 4.5}px;
        max-width: 100%;
    `
)

export const StyledQuizItemLabelContainer = styled.div<{$isCorrect?: boolean}>(
    ({theme: {palette, spacing}, $isCorrect}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: minmax(0, 1fr) 20px;
        align-items: center;
        gap: ${spacing * 2}px;
        & svg {
            color: ${$isCorrect ? palette.success[600] : palette.danger[600]};
        }
    `
)
