import styled, {css} from 'styled-components'
import {ExperiencesVideoAnswerItem} from '@/features/experiences-video/components/experiences-video-answer-item/ExperiencesVideoAnswerItem.tsx'

export const StyledRecapLocationCard = styled(ExperiencesVideoAnswerItem)<{$isReadonly: boolean}>(
    ({theme: {spacing, typography, palette, shadows}, $isReadonly}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        gap: ${spacing * 0.5};
        ${typography.textSm};
        font-weight: 400;

        & h3 {
            font-weight: 500;
        }

        & li:before {
            content: '• ';
        }

        & .location-card-checkbox {
            border: 1px solid ${palette.neutral['300']};
        }

        &:has(input:checked) {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 3px ${palette.success['600']}`};
            & .location-card-checkbox {
                border: none;
                background-color: ${palette.success['600']};
            }
        }

        ${$isReadonly &&
        css`
            & .location-card-checkbox {
                border: none;
                background-color: ${palette.neutral['700']};
            }
        `}
    `
)

export const StyledCheckbox = styled.div(
    ({theme: {palette}}) => css`
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${palette.neutral.white};
        border-radius: 6px;
    `
)
