import {createBrowserRouter} from 'react-router-dom'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {AppLayout} from '@layouts/app-layout/AppLayout.tsx'
import {routes} from '@utilities/constants'
import ServiceRoute from '@route-guards/service-route/ServiceRoute.tsx'
import DashboardRoute from '@route-guards/dashboard-route/DashboardRoute.tsx'
import PrivateRoute from '@route-guards/private-route/PrivateRoute.tsx'
import TermsRoute from '@route-guards/terms-route/TermsRoute.tsx'
import {CoAlarmListingsWrapper} from '@/features/co-alarm/components/co-alarm-listings-wrapper/CoAlarmListingsWrapper'
import HostApprovalRoute from '@route-guards/host-approval-route/HostApprovalRoute.tsx'

const router = createBrowserRouter(
    [
        {
            ...routes.NOT_FOUND
        },
        {
            element: <PublicRoute />,
            children: [
                {
                    element: <AppLayout withHeader />,
                    children: [{...routes.SSO_REDIRECT}, {...routes.PHOTOSHOOT_LANDING}, {...routes.SSO_LOGIN}]
                },
                {...routes.EXPERIENCE_VIDEO},
                {...routes.EXPERIENCE_VIDEO_HOST_FRIEND}
            ]
        },

        {
            element: <PrivateRoute />,
            children: [
                {
                    element: <AppLayout withHeader />,
                    children: [
                        {...routes.HOME},
                        {...routes.PHOTOSHOOT_REQUEST},
                        {...routes.CATEGORIES},
                        {...routes.CO_ALARM_DASHBOARD},
                        {...routes.CO_ALARM_INACTIVE},
                        {...routes.CO_ALARM_SEND_REQUEST},
                        {
                            element: <CoAlarmListingsWrapper />,
                            children: [{...routes.CO_ALARM_REQUEST_DETAILS}]
                        }
                    ]
                }
            ]
        },
        {
            element: <AppLayout withHeader />,
            children: [
                {
                    element: <HostApprovalRoute />,
                    children: [{...routes.APPROVAL_REQUEST_DETAILS}]
                },
                {
                    element: <ServiceRoute />,
                    children: [{...routes.SERVICE_DETAILS}]
                },
                {
                    element: <DashboardRoute />,
                    children: [{...routes.DASHBOARD}]
                },
                {
                    element: <TermsRoute />,
                    children: [{...routes.TERMS_OF_SERVICE}]
                }
            ]
        }
        /* {
            element: (
                <>
                    <Outlet />
                </>
            ),
            children: [
                {
                    element: <PublicLayout />,
                    children: [{...routes.COOKIE_POLICY}]
                }
            ]
        }*/
    ],
    {
        basename: '/'
    }
)

export default router
