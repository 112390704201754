import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {spacing} from '@/theme'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'

export const StyledExperiencesVideoHeaderWrapper = styled(Flexbox)(
    ({theme: {palette}}) => css`
        position: sticky;
        top: 0;
        background: ${palette.neutral.white};
        height: 40px;
        padding: 0 ${spacing * 4}px;
        width: 100%;
        border-bottom: 1px solid ${palette.neutral['200']};
    `
)

export const StyledExperiencesVideoHeaderButton = styled(ButtonLink)(
    () => css`
        padding: 0;
    `
)
