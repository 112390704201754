import {
    ExperiencesVideoProgressbarIndicator,
    StyledExperiencesVideoProgressbar
} from '@/features/experiences-video/components/progress-bar/style.ts'
import {FC} from 'react'

export const ProgressBar: FC<{percentage: number}> = ({percentage}) => (
    <StyledExperiencesVideoProgressbar>
        <ExperiencesVideoProgressbarIndicator style={{width: `${percentage}%`}} />
    </StyledExperiencesVideoProgressbar>
)
