import {RequirementCard} from '@/features/experiences-video/components/requirement-card/RequirementCard.tsx'
import {Grid02Icon, Users01Icon} from '@components/ui/icon'
import {PerspectiveVideos} from '@/features/experiences-video/components/prespective-videos/PerspectiveVideos.tsx'
import {useTranslation} from 'react-i18next'
import {CommonRequirements} from '@/features/experiences-video/components/common-requirements/CommonRequirements.tsx'
import {StyledRequirementCardWrapper} from '@/features/experiences-video/components/common-requirements/style.ts'

export const FullRequirements = () => {
    const {t} = useTranslation()

    return (
        <>
            <CommonRequirements />
            <StyledRequirementCardWrapper>
                <RequirementCard icon={<Grid02Icon />}>
                    <h3>{t('experiences_video:preparation_requirements:perspective_title')}</h3>
                    <p>{t('experiences_video:preparation_requirements:perspective_description')}</p>
                </RequirementCard>
            </StyledRequirementCardWrapper>
            <PerspectiveVideos scrollSpacing={16} />
            <StyledRequirementCardWrapper>
                <RequirementCard icon={<Users01Icon />}>
                    <h3>{t('experiences_video:preparation_requirements:dont_film_own_title')}</h3>
                    <p>{t('experiences_video:preparation_requirements:dont_film_own_description')}</p>
                </RequirementCard>
            </StyledRequirementCardWrapper>
        </>
    )
}
