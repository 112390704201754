import {useTranslation} from 'react-i18next'
import {Dispatch, FC, SetStateAction} from 'react'
import {RequirementsModalStep} from '@/features/experiences-video/components/host-friend-requirements-modal/HostFriendRequirementsModal.tsx'
import {
    ExperiencesVideoFooter,
    ExperiencesVideoModalBody
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ProgressBar} from '@/features/experiences-video/components/progress-bar/ProgressBar.tsx'
import {
    StyledHostFriendRequirementsModalPerspectiveStep,
    StyledTitle
} from '@/features/experiences-video/components/host-friend-requirements-modal-technical-step/style.ts'
import {CommonRequirements} from '@/features/experiences-video/components/common-requirements/CommonRequirements.tsx'

interface HostFriendRequirementsModalTechnicalStepProps {
    setStep: Dispatch<SetStateAction<RequirementsModalStep>>
}
export const HostFriendRequirementsModalTechnicalStep: FC<HostFriendRequirementsModalTechnicalStepProps> = ({
    setStep
}) => {
    const {t} = useTranslation()

    return (
        <StyledHostFriendRequirementsModalPerspectiveStep>
            <StyledTitle>{t('experiences_video:technical_requirements')}</StyledTitle>
            <ExperiencesVideoModalBody style={{paddingLeft: 0, paddingRight: 0}}>
                <CommonRequirements />
            </ExperiencesVideoModalBody>
            <ExperiencesVideoFooter style={{maxWidth: '100%'}}>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <Flexbox width="100%" gap={1}>
                        <ProgressBar percentage={100} />
                        <ProgressBar percentage={0} />
                        <ProgressBar percentage={0} />
                    </Flexbox>
                    <Flexbox direction="column" gap={4.5} width="100%">
                        <Flexbox justify="space-between" width="100%">
                            <Button variant="tertiary" onClick={() => setStep('intro')}>
                                {t('commons:back')}
                            </Button>
                            <Button variant="primary" onClick={() => setStep('perspective')}>
                                {t('commons:continue')}
                            </Button>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>
        </StyledHostFriendRequirementsModalPerspectiveStep>
    )
}
