import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {
    StyledHostFriendRequirementsModalIntroStep,
    StyledHostFriendRequirementsModalIntroStepBody,
    StyledHostFriendRequirementsModalIntroStepFooter
} from '@/features/experiences-video/components/host-friend-requirements-modal-intro-step/style.ts'
import {Dispatch, FC, SetStateAction} from 'react'
import {RequirementsModalStep} from '@/features/experiences-video/components/host-friend-requirements-modal/HostFriendRequirementsModal.tsx'
import director from '@assets/images/experiences-video/director.svg'

export const HostFriendRequirementsModalIntroStep: FC<{setStep: Dispatch<SetStateAction<RequirementsModalStep>>}> = ({
    setStep
}) => {
    const {t} = useTranslation()

    return (
        <StyledHostFriendRequirementsModalIntroStep>
            <StyledHostFriendRequirementsModalIntroStepBody>
                <img src={director} alt="step image" />
                <Flexbox direction="column" align="center" gap={2} width="100%">
                    <ExperiencesVideoTitle>
                        {t('experiences_video:host_friend_get_ready:requirements_modal:intro:title')}
                    </ExperiencesVideoTitle>
                    <ExperiencesVideoParagraph>
                        {t('experiences_video:host_friend_get_ready:requirements_modal:intro:description')}
                    </ExperiencesVideoParagraph>
                </Flexbox>
            </StyledHostFriendRequirementsModalIntroStepBody>
            <StyledHostFriendRequirementsModalIntroStepFooter>
                <Button fullWidth onClick={() => setStep('technical')}>
                    {t('experiences_video:host_friend_get_ready:requirements_modal:intro:get_started')}
                </Button>
            </StyledHostFriendRequirementsModalIntroStepFooter>
        </StyledHostFriendRequirementsModalIntroStep>
    )
}
