import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {
    httpUpdateExperiencesVideo,
    HttpUpdateExperiencesVideoOptions
} from '@/features/experiences-video/services/experiencesVideo.http.ts'

export const useUpdateExperiencesVideo = ({
    urlParams,
    options
}: {
    urlParams: HttpUpdateExperiencesVideoOptions['urlParams']
    options?: Omit<UseMutationOptions<unknown, unknown, HttpUpdateExperiencesVideoOptions['payload']>, 'mutationFn'>
}) =>
    useMutation<unknown, unknown, HttpUpdateExperiencesVideoOptions['payload']>({
        mutationFn: payload => httpUpdateExperiencesVideo({urlParams, payload}),
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context)
        }
    })
