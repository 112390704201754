import styled, {css} from 'styled-components'
import {SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {
    ExperiencesVideoModalBody,
    ExperiencesVideoParagraph
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

export const StyledHostFriendRequirementsModalPerspectiveStep = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
`

export const StyledTitle = styled(SlidingModalHeader)(
    ({theme: {typography, spacing}}) => css`
        padding-left: ${spacing * 4}px;
        padding-top: ${spacing * 16}px;
        ${typography.displayXs};
    `
)

export const StyledBody = styled(ExperiencesVideoModalBody)`
    padding-left: 0;
    padding-right: 0;
`

export const StyledParagraph = styled(ExperiencesVideoParagraph)(
    ({theme: {spacing}}) => css`
        padding-left: ${spacing * 4}px;
        padding-top: ${spacing * 4}px;
    `
)
