import {
    StyledRequirementCard,
    StyledIconWrapper
} from '@/features/experiences-video/components/requirement-card/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FC, HTMLAttributes, ReactElement} from 'react'

export const RequirementCard: FC<HTMLAttributes<HTMLDivElement> & {icon: ReactElement}> = ({
    children,
    icon,
    ...rest
}) => {
    return (
        <StyledRequirementCard {...rest}>
            <StyledIconWrapper>{icon}</StyledIconWrapper>
            <Flexbox direction="column" gap={2}>
                {children}
            </Flexbox>
        </StyledRequirementCard>
    )
}
