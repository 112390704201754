import {useTheme} from 'styled-components'
import {FC} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesVideoLabel} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {RecapLocationCard} from '@/features/experiences-video/components/recap-location-card/RecapLocationCard.tsx'
import {
    experienceVideoDayTimeToIcon,
    experienceVideoDayTimeToLabelTranslationKey,
    experienceVideoEmotionToIcon,
    experienceVideoEmotionToLabelTranslationKey,
    experienceVideoExpertiseToLabelTranslationKey,
    experienceVideoVenueToLabelTranslationKey
} from '@/features/experiences-video/utils.tsx'
import {ExperiencesVideoHostFriendStore} from '@/features/experiences-video/stores/experiencesVideoHostFriend.ts'
import {UseFormRegisterReturn} from 'react-hook-form'
import {ErrorMessage} from '@components/ui/error-message/ErrorMessage.tsx'

interface LocationsRecapProps {
    formRegister?: UseFormRegisterReturn<'shootList'>
    formErrorMessage?: string
    isReadonly?: boolean
    shootList: Omit<ExperiencesVideoHostFriendStore['shootList'], 'venue'> & {
        venue: NonNullable<ExperiencesVideoHostFriendStore['shootList']['venue']>
    }
}
export const LocationsRecap: FC<LocationsRecapProps> = ({shootList, isReadonly, formRegister, formErrorMessage}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <Flexbox direction="column" gap={6.5} width="100%">
            {[
                ...new Set([
                    shootList.initialLocation,
                    shootList.expertisesLocation,
                    shootList.interactionsLocation,
                    shootList.emotionsLocation,
                    shootList.dayTimesVenueLocation
                ])
            ].map(location => (
                <Flexbox direction="column" gap={4} width="100%" key={location}>
                    <ExperiencesVideoLabel style={{color: theme.palette.neutral['700']}}>
                        <Trans
                            i18nKey="experiences_video:preparation_recap:film_following_key_moments"
                            components={[<b />]}
                            values={{location}}
                        />
                    </ExperiencesVideoLabel>
                    <Flexbox direction="column" gap={3} width="100%">
                        {location == shootList.initialLocation && (
                            <RecapLocationCard {...formRegister} readOnly={isReadonly} value="initialLocation">
                                <h3>{t('experiences_video:preparation_recap:initial_location_label')}</h3>
                                <p>{t('experiences_video:preparation_recap:initial_location_description')}</p>
                            </RecapLocationCard>
                        )}
                        {location == shootList.expertisesLocation && (
                            <RecapLocationCard {...formRegister} readOnly={isReadonly} value="expertisesLocation">
                                <h3>{t('experiences_video:preparation_recap:expertises_label')}</h3>
                                <p>{t('experiences_video:preparation_recap:expertises_description')}</p>
                                <ol>
                                    {shootList.expertises.map(expertise => (
                                        <li key={expertise}>
                                            {t(experienceVideoExpertiseToLabelTranslationKey[expertise])}
                                        </li>
                                    ))}
                                </ol>
                            </RecapLocationCard>
                        )}
                        {location == shootList.interactionsLocation && (
                            <RecapLocationCard {...formRegister} readOnly={isReadonly} value="interactionsLocation">
                                <h3>{t('experiences_video:preparation_recap:interactions_label')}</h3>
                                <p>{t('experiences_video:preparation_recap:interactions_description')}</p>
                                {shootList.interactions.length >= 1 ? (
                                    <ol>
                                        {shootList.interactions.map(interaction => (
                                            <li key={interaction}>{interaction}</li>
                                        ))}
                                    </ol>
                                ) : (
                                    '-'
                                )}
                            </RecapLocationCard>
                        )}
                        {location == shootList.emotionsLocation && (
                            <RecapLocationCard {...formRegister} readOnly={isReadonly} value="emotionsLocation">
                                <h3>{t('experiences_video:preparation_recap:emotions_label')}</h3>
                                <p>{t('experiences_video:preparation_recap:emotions_description')}</p>
                                <Flexbox gap={4} style={{flexWrap: 'wrap'}}>
                                    {shootList.emotions.map(emotion => (
                                        <Flexbox gap={2} align="center" key={emotion}>
                                            {experienceVideoEmotionToIcon[emotion]}
                                            {t(experienceVideoEmotionToLabelTranslationKey[emotion])}
                                        </Flexbox>
                                    ))}
                                </Flexbox>
                            </RecapLocationCard>
                        )}
                        {location == shootList.dayTimesVenueLocation && (
                            <RecapLocationCard {...formRegister} readOnly={isReadonly} value="dayTimesVenueLocation">
                                <h3>{t('experiences_video:preparation_recap:daytimes_venue_label')}</h3>
                                <p>{t(experienceVideoVenueToLabelTranslationKey[shootList.venue])}</p>
                                <Flexbox gap={4} style={{flexWrap: 'wrap'}}>
                                    {shootList.dayTimes.map(dayTime => (
                                        <Flexbox gap={2} align="center" key={dayTime}>
                                            {experienceVideoDayTimeToIcon[dayTime]}
                                            {t(experienceVideoDayTimeToLabelTranslationKey[dayTime])}
                                        </Flexbox>
                                    ))}
                                </Flexbox>
                            </RecapLocationCard>
                        )}
                        {!!formErrorMessage && <ErrorMessage>{formErrorMessage}</ErrorMessage>}
                    </Flexbox>
                </Flexbox>
            ))}
        </Flexbox>
    )
}
