import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    ExperiencesVideoBody,
    ExperiencesVideoFooter,
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {StyledViewSomeExampleButton} from '@/features/experiences-video/components/intro-step/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import filmProps from '@assets/images/experiences-video/film_props.svg'
import director from '@assets/images/experiences-video/director.svg'
import upload from '@assets/images/experiences-video/upload.svg'
import {
    useExperiencesVideoStore,
    useExperiencesVideoStoreValues
} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {useState} from 'react'
import {TrailerExampleModal} from '@/features/experiences-video/components/trailer-example-modal/TrailerExampleModal.tsx'
import {IntroStepsTimeline} from '@/features/experiences-video/components/intro-steps-timeline/IntroStepsTimeline.tsx'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useUpdateExperiencesVideo} from '@/features/experiences-video/services/useUpdateExperiencesVideo.ts'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import toast from 'react-hot-toast'

export const IntroStep = () => {
    const {t} = useTranslation()
    const urlParams = useExperiencesVideoUrlParams()
    const storeValues = useExperiencesVideoStoreValues()
    const setStep = useExperiencesVideoStore(state => state.setStep)
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const updateExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('preparation_intro'),
            onError: () => toast.error(t('errors:default'))
        }
    })

    return (
        <>
            <ExperiencesVideoBody>
                <Flexbox direction={'column'} gap={4}>
                    <ExperiencesVideoTitle>{t('experiences_video:intro:title')}</ExperiencesVideoTitle>
                    <ExperiencesVideoParagraph>
                        <Trans
                            i18nKey={'experiences_video:intro:description'}
                            components={[<StyledViewSomeExampleButton onClick={() => setSlidingModalState('open')} />]}
                        />
                    </ExperiencesVideoParagraph>
                </Flexbox>
                <Flexbox direction={'column'} gap={6} align={'stretch'}>
                    <IntroStepsTimeline
                        number={1}
                        title={t('experiences_video:preparation')}
                        paragraph={t('experiences_video:intro:preparation_description')}
                        image={filmProps}
                    />
                    <IntroStepsTimeline
                        number={2}
                        title={t('experiences_video:get_ready_to_film')}
                        paragraph={t('experiences_video:intro:get_ready_description')}
                        image={director}
                    />
                    <IntroStepsTimeline
                        number={3}
                        title={t('experiences_video:upload')}
                        paragraph={t('experiences_video:intro:upload_description')}
                        image={upload}
                    />
                </Flexbox>
            </ExperiencesVideoBody>
            <ExperiencesVideoFooter>
                <Button
                    onClick={() =>
                        updateExperiencesVideoMutation.mutate({data: {...storeValues, step: 'preparation_intro'}})
                    }
                    disabled={updateExperiencesVideoMutation.isPending}
                    fullWidth
                >
                    {t('experiences_video:intro:cta')}
                    {/*margin 0 needed because the Spinner component has margin auto*/}
                    {updateExperiencesVideoMutation.isPending && <Spinner style={{margin: 0}} />}
                </Button>
            </ExperiencesVideoFooter>

            {slidingModalState != 'closed' && (
                <TrailerExampleModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                />
            )}
        </>
    )
}
