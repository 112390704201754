import {
    ExperiencesVideoDayTime,
    ExperiencesVideoEmotions,
    ExperiencesVideoExpertise,
    ExperiencesVideoVenue
} from '@/features/experiences-video/types.ts'
import {
    FaceContentIcon,
    FaceHappyIcon,
    FaceNeutralIcon,
    FeatherIcon,
    HeartHandIcon,
    Moon01Icon,
    SearchLgIcon,
    Star01Icon,
    SunIcon,
    SunriseIcon,
    SunsetIcon,
    Target04Icon
} from '@components/ui/icon'
import {ReactElement} from 'react'

export const steps = {
    intro: ['intro'],
    preparation: [
        'preparation_intro',
        'preparation_initial_location',
        'preparation_expertises',
        'preparation_interactions',
        'preparation_emotions',
        'preparation_daytimes_venue',
        'preparation_recap',
        'preparation_requirements'
    ],
    get_ready: ['get_ready_intro', 'get_ready_share_schedule'],
    upload: []
} as const

export type Step = (typeof steps)[keyof typeof steps][number]

const calculatePercentage = (index: number, stepsLength: number) => ((index + 1) / stepsLength) * 100

type ProgressResult = [number, number, number]
export const stepToProgress = (step: Step): ProgressResult => {
    const preparationIndex = steps['preparation'].findIndex(preparationStep => preparationStep == step)
    if (preparationIndex >= 0) {
        return [calculatePercentage(preparationIndex, steps['preparation'].length), 0, 0]
    }

    const getReadyIndex = steps['get_ready'].findIndex(preparationStep => preparationStep == step)
    if (getReadyIndex >= 0) {
        return [100, calculatePercentage(getReadyIndex, steps['get_ready'].length), 0]
    }

    const uploadIndex = steps['upload'].findIndex(preparationStep => preparationStep == step)
    if (uploadIndex >= 0) {
        return [100, 100, calculatePercentage(uploadIndex, steps['upload'].length)]
    }

    return [0, 0, 0]
}

export const experienceVideoExpertiseToLabelTranslationKey = {
    equipment: 'experiences_video:preparation_expertises:equipment_label',
    technical_action: 'experiences_video:preparation_expertises:technical_action_label',
    speaking: 'experiences_video:preparation_expertises:speaking_label',
    showing_surroundings: 'experiences_video:preparation_expertises:showing_surroundings_label',
    other: 'commons:other'
} as const satisfies Record<ExperiencesVideoExpertise, string>

export const experienceVideoEmotionToIcon = {
    joy: <FaceContentIcon />,
    fear: <FaceNeutralIcon />,
    surprise: <FaceHappyIcon />,
    friendship: <HeartHandIcon />,
    curiosity: <SearchLgIcon />,
    focus: <Target04Icon />,
    excitement: <Star01Icon />,
    relaxation: <FeatherIcon />
} as const satisfies Record<ExperiencesVideoEmotions, ReactElement>

export const experienceVideoEmotionToLabelTranslationKey = {
    joy: 'experiences_video:preparation_emotions:joy_label',
    fear: 'experiences_video:preparation_emotions:fear_label',
    surprise: 'experiences_video:preparation_emotions:surprise_label',
    friendship: 'experiences_video:preparation_emotions:friendship_label',
    curiosity: 'experiences_video:preparation_emotions:curiosity_label',
    focus: 'experiences_video:preparation_emotions:focus_label',
    excitement: 'experiences_video:preparation_emotions:excitement_label',
    relaxation: 'experiences_video:preparation_emotions:relaxation_label'
} as const satisfies Record<ExperiencesVideoEmotions, string>

export const experienceVideoDayTimeToIcon = {
    sunrise: <SunriseIcon />,
    morning: <SunIcon />,
    sunset: <SunsetIcon />,
    afternoon: <Moon01Icon />
} as const satisfies Record<ExperiencesVideoDayTime, ReactElement>

export const experienceVideoDayTimeToLabelTranslationKey = {
    sunrise: 'experiences_video:preparation_daytimes_venue:sunrise_label',
    morning: 'experiences_video:preparation_daytimes_venue:morning_label',
    sunset: 'experiences_video:preparation_daytimes_venue:sunset_label',
    afternoon: 'experiences_video:preparation_daytimes_venue:afternoon_label'
} as const satisfies Record<ExperiencesVideoDayTime, string>

export const experienceVideoVenueToLabelTranslationKey = {
    restaurant_bar: 'experiences_video:preparation_daytimes_venue:restaurant_bar_label',
    public_venue_business: 'experiences_video:preparation_daytimes_venue:public_venue_business_label',
    private_home: 'experiences_video:preparation_daytimes_venue:private_home_label',
    outdoor: 'experiences_video:preparation_daytimes_venue:outdoor_label'
} as const satisfies Record<ExperiencesVideoVenue, string>
