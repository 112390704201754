import {create} from 'zustand'
import {Step} from '@/features/experiences-video/utils.tsx'
import {
    ExperiencesVideo,
    ExperiencesVideoDayTime,
    ExperiencesVideoEmotions,
    ExperiencesVideoExpertise,
    ExperiencesVideoVenue
} from '@/features/experiences-video/types.ts'

interface InitialValues {
    step: Step
    initialLocation: string | null
    expertises: ExperiencesVideoExpertise[]
    expertisesLocation: string | null
    doNotInteract: boolean
    interactions: string[]
    interactionsLocation: string | null
    emotionsNotSure: boolean
    emotions: ExperiencesVideoEmotions[]
    emotionsLocation: string | null
    dayTimes: ExperiencesVideoDayTime[]
    venue: ExperiencesVideoVenue | null
    dayTimesVenueLocation: string | null
}

const initialValues: InitialValues = {
    step: 'intro',
    initialLocation: null,
    expertisesLocation: null,
    expertises: [],
    doNotInteract: false,
    interactions: [],
    interactionsLocation: null,
    emotionsNotSure: false,
    emotions: [],
    emotionsLocation: null,
    dayTimes: [],
    venue: null,
    dayTimesVenueLocation: null
}

export interface ExperiencesVideoStore extends InitialValues {
    setStep: (step: ExperiencesVideoStore['step']) => void
    setInitialLocation: (initialLocation: string) => void
    setExpertisesLocation: (expertisesLocation: string) => void
    setExpertises: (expertises: ExperiencesVideoExpertise[]) => void
    setDoNotInteract: (doNotInteract: boolean) => void
    setInteractions: (interactions: string[]) => void
    setInteractionsLocation: (interactionsLocation: string) => void
    setEmotionsNotSure: (emotionsNotSure: boolean) => void
    setEmotions: (emotions: ExperiencesVideoEmotions[]) => void
    setEmotionsLocation: (emotionsLocation: string) => void
    setDayTimes: (dayTimes: ExperiencesVideoDayTime[]) => void
    setVenue: (venue: ExperiencesVideoVenue) => void
    setDayTimesVenueLocation: (dayTimesVenueLocation: string) => void
    setStoreValues: (storeValues: NonNullable<ExperiencesVideo['data']>) => void
    resetStore: () => void
}

export const useExperiencesVideoStore = create<ExperiencesVideoStore>()(set => ({
    ...initialValues,
    setStep: step => set({step}),
    setInitialLocation: initialLocation => set({initialLocation}),
    setExpertisesLocation: expertisesLocation => set({expertisesLocation}),
    setExpertises: expertises => set({expertises}),
    setDoNotInteract: doNotInteract => set({doNotInteract}),
    setInteractions: interactions => set({interactions}),
    setInteractionsLocation: interactionsLocation => set({interactionsLocation}),
    setEmotionsNotSure: emotionsNotSure => set({emotionsNotSure}),
    setEmotions: emotions => set({emotions}),
    setEmotionsLocation: emotionsLocation => set({emotionsLocation}),
    setDayTimes: dayTimes => set({dayTimes}),
    setVenue: venue => set({venue}),
    setDayTimesVenueLocation: dayTimesVenueLocation => set({dayTimesVenueLocation}),
    setStoreValues: storeValues => set({...storeValues}),
    resetStore: () => set(initialValues)
}))

export const useExperiencesVideoStoreValues = () => {
    const store = useExperiencesVideoStore()
    return {
        step: store.step,
        initialLocation: store.initialLocation,
        expertisesLocation: store.expertisesLocation,
        expertises: store.expertises,
        doNotInteract: store.doNotInteract,
        interactions: store.interactions,
        interactionsLocation: store.interactionsLocation,
        emotionsNotSure: store.emotionsNotSure,
        emotions: store.emotions,
        emotionsLocation: store.emotionsLocation,
        dayTimes: store.dayTimes,
        venue: store.venue,
        dayTimesVenueLocation: store.dayTimesVenueLocation
    }
}
