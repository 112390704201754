import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledPerspectiveCard,
    StyledPerspectiveVideo,
    StyledPerspectiveVideoWrapper
} from '@/features/experiences-video/components/prespective-videos/style.ts'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {FC, useRef} from 'react'
import {useVideoController} from '@/features/experiences-video/hooks/useVideoController.ts'
import {StyledLoadingVideoWrapper} from '@/features/experiences-video/components/video-trailer-controller/style.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import firstPerspectiveVideoPoster from '@assets/images/experiences-video/first_perspective_video_poster.png'
import secondPerspectiveVideoPoster from '@assets/images/experiences-video/second_perspective_video_poster.png'
import thirdPerspectiveVideoPoster from '@assets/images/experiences-video/third_perspective_video_poster.png'

export const PerspectiveVideos: FC<{scrollSpacing?: number}> = ({scrollSpacing = 0}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const firstVideoRef = useRef<HTMLVideoElement>(null)
    const secondVideoRef = useRef<HTMLVideoElement>(null)
    const thirdVideoRef = useRef<HTMLVideoElement>(null)
    const firstVideoController = useVideoController(firstVideoRef, {initialVideoState: 'playing', defaultMuted: true})
    const secondVideoController = useVideoController(secondVideoRef, {initialVideoState: 'playing', defaultMuted: true})
    const thirdVideoController = useVideoController(thirdVideoRef, {initialVideoState: 'playing', defaultMuted: true})

    return (
        <ScrollArea
            scrollbar={<Scrollbar orientation="horizontal" style={{marginBottom: -8}} />}
            style={{width: '100%'}}
        >
            <Flexbox gap={4} style={{padding: `0 ${theme.spacing * scrollSpacing}px`}}>
                <StyledPerspectiveCard>
                    <h4>{t('experiences_video:preparation_requirements:wide')}</h4>
                    <StyledPerspectiveVideoWrapper>
                        {firstVideoController.videoState == 'loading' && (
                            <StyledLoadingVideoWrapper>
                                <Spinner size={30} />
                            </StyledLoadingVideoWrapper>
                        )}
                        <StyledPerspectiveVideo
                            ref={firstVideoRef}
                            poster={firstPerspectiveVideoPoster}
                            loop
                            playsInline
                            disableRemotePlayback
                            disablePictureInPicture
                            {...firstVideoController}
                        >
                            <source
                                src={'https://videos.pexels.com/video-files/7235796/7235796-uhd_1440_2560_30fps.mp4'}
                            />
                        </StyledPerspectiveVideo>
                    </StyledPerspectiveVideoWrapper>
                </StyledPerspectiveCard>
                <StyledPerspectiveCard>
                    <h4>{t('experiences_video:preparation_requirements:medium')}</h4>
                    <StyledPerspectiveVideoWrapper>
                        {secondVideoController.videoState == 'loading' && (
                            <StyledLoadingVideoWrapper>
                                <Spinner size={30} />
                            </StyledLoadingVideoWrapper>
                        )}
                        <StyledPerspectiveVideo
                            ref={secondVideoRef}
                            poster={secondPerspectiveVideoPoster}
                            loop
                            playsInline
                            disableRemotePlayback
                            disablePictureInPicture
                            {...secondVideoController}
                        >
                            <source
                                src={'https://videos.pexels.com/video-files/5823707/5823707-uhd_1440_2560_24fps.mp4'}
                            />
                        </StyledPerspectiveVideo>
                    </StyledPerspectiveVideoWrapper>
                </StyledPerspectiveCard>
                <StyledPerspectiveCard>
                    <h4>{t('experiences_video:preparation_requirements:close_up')}</h4>
                    <StyledPerspectiveVideoWrapper>
                        {thirdVideoController.videoState == 'loading' && (
                            <StyledLoadingVideoWrapper>
                                <Spinner size={30} />
                            </StyledLoadingVideoWrapper>
                        )}
                        <StyledPerspectiveVideo
                            ref={thirdVideoRef}
                            poster={thirdPerspectiveVideoPoster}
                            loop
                            playsInline
                            disableRemotePlayback
                            disablePictureInPicture
                            {...thirdVideoController}
                        >
                            <source
                                src={'https://videos.pexels.com/video-files/5644324/5644324-hd_1080_1920_25fps.mp4'}
                            />
                        </StyledPerspectiveVideo>
                    </StyledPerspectiveVideoWrapper>
                </StyledPerspectiveCard>
            </Flexbox>
        </ScrollArea>
    )
}
