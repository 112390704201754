import {
    QuizModalAnswerResponse,
    QuizModalFooter,
    QuizModalQuestion,
    QuizModalQuestionHint,
    QuizItemLabelContainer
} from '@/features/experiences-video/components/quiz-modal-atoms/QuizModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesVideoAnswerItem} from '@/features/experiences-video/components/experiences-video-answer-item/ExperiencesVideoAnswerItem.tsx'
import {
    ExperiencesVideoQuizStore,
    useExperiencesVideoQuizStore
} from '@/features/experiences-video/stores/experiencesVideoQuiz.ts'
import {CheckCircleBrokenIcon, XCircleIcon} from '@components/ui/icon'
import {ExperiencesVideoModalBody} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

const optionToTranslationKey = {
    my_self: 'experiences_video:host_friend_get_ready:quiz:hold_camera:my_self',
    friend: 'experiences_video:host_friend_get_ready:quiz:hold_camera:friend'
} as const satisfies Record<NonNullable<ExperiencesVideoQuizStore['holdCameraAnswer']>, string>

export const QuizModalHoldCameraStep = () => {
    const {t} = useTranslation()

    const setHoldCameraAnswer = useExperiencesVideoQuizStore(store => store.setHoldCameraAnswer)
    const setStep = useExperiencesVideoQuizStore(store => store.setStep)
    const holdCameraAnswer = useExperiencesVideoQuizStore(store => store.holdCameraAnswer)

    return (
        <>
            <ExperiencesVideoModalBody>
                <Flexbox direction="column" gap={2}>
                    <QuizModalQuestion>
                        {t('experiences_video:host_friend_get_ready:quiz:hold_camera:question')}
                    </QuizModalQuestion>
                    <QuizModalQuestionHint>
                        {t('experiences_video:host_friend_get_ready:quiz:tap_options')}
                    </QuizModalQuestionHint>
                </Flexbox>
                <Flexbox direction="column" gap={2} width="100%" align="stretch">
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer>
                                {t(optionToTranslationKey['my_self'])}
                                {holdCameraAnswer == 'my_self' && <XCircleIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="my_self"
                        name="option"
                        onChange={() => setHoldCameraAnswer('my_self')}
                        checked={holdCameraAnswer == 'my_self'}
                        disabled={!!holdCameraAnswer && holdCameraAnswer != 'my_self'}
                    />
                    <ExperiencesVideoAnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect>
                                {t(optionToTranslationKey['friend'])}
                                {holdCameraAnswer == 'friend' && <CheckCircleBrokenIcon size={20} />}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="friend"
                        name="option"
                        onChange={() => setHoldCameraAnswer('friend')}
                        checked={holdCameraAnswer == 'friend'}
                        disabled={!!holdCameraAnswer && holdCameraAnswer != 'friend'}
                    />
                </Flexbox>
                {holdCameraAnswer && (
                    <QuizModalAnswerResponse
                        variant={holdCameraAnswer == 'friend' ? 'success' : 'danger'}
                        title={t(
                            `experiences_video:host_friend_get_ready:quiz:${
                                holdCameraAnswer == 'friend' ? 'answer_correct' : 'answer_incorrect'
                            }`
                        )}
                        paragraph={t('experiences_video:host_friend_get_ready:quiz:hold_camera:description')}
                    />
                )}
            </ExperiencesVideoModalBody>
            <QuizModalFooter progressPercentages={[100, 100, 100, 0, 0]}>
                <Button disabled={!holdCameraAnswer} onClick={() => setStep('staged_people_count')}>
                    {t('experiences_video:host_friend_get_ready:quiz:next_question')}
                </Button>
            </QuizModalFooter>
        </>
    )
}
