import styled, {css} from 'styled-components'
import {
    ExperiencesVideoFooter,
    ExperiencesVideoModalBody
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

export const StyledHostFriendRequirementsModalIntroStep = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto;
`

export const StyledHostFriendRequirementsModalIntroStepBody = styled(ExperiencesVideoModalBody)(
    ({theme: {spacing}}) => css`
        gap: ${spacing * 8}px;
        text-align: center;

        & img {
            align-self: center;
            width: 300px;
            height: 300px;
        }
    `
)

export const StyledHostFriendRequirementsModalIntroStepFooter = styled(ExperiencesVideoFooter)(
    ({theme: {palette, spacing}}) => css`
        padding-top: ${spacing * 4}px;
        border-top: 1px solid ${palette.neutral['300']};
        max-width: 100%;
    `
)
