import * as pages from '@/pages'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import Button from '@components/ui/button-legacy/Button.tsx'
import i18next from 'i18next'
import {AlertCircleIcon} from 'src/components/ui/icon'

export const routes = {
    PHOTOSHOOT_LANDING: {
        path: '/pro-photography',
        element: <pages.ProPhotographyLanding />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:photoshoot_landing'
        }
    },
    SSO_REDIRECT: {
        path: '/redirect',
        element: <pages.SsoRedirect />
    },
    SSO_LOGIN: {
        path: '/login',
        element: <pages.SsoLogin />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    HOME: {
        path: '/',
        element: <pages.Home />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:home'
        }
    },
    DASHBOARD: {
        path: '/dashboard/:hostCode/:hostPassword',
        element: <pages.Dashboard />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        ),
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:dashboard'
        }
    },
    EXPERIENCE_VIDEO: {
        path: '/dashboard/:hostCode/:hostPassword/experiences-video/:experiencesVideoId',
        element: <pages.ExperiencesVideo />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        )
    },
    EXPERIENCE_VIDEO_HOST_FRIEND: {
        path: '/dashboard/:hostCode/:hostPassword/experiences-video-host-friend/:experiencesVideoId',
        element: <pages.ExperiencesVideoHostFriend />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        )
    },
    SERVICE_DETAILS: {
        path: '/hosts/:hostCode/:hostPassword/quotes/:quoteId',
        element: <pages.ServiceDetails />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        ),
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:timeline'
        }
    },
    APPROVAL_REQUEST_DETAILS: {
        path: '/hosts/:hostCode/:hostPassword/approval-request/:requestId',
        element: <pages.HostApprovalRequest />,
        errorElement: (
            <ErrorBox
                cta={
                    <Button variant="primary" onClick={() => window.location.reload()}>
                        {i18next.t('commons:reload_the_page')}
                    </Button>
                }
                icon={<AlertCircleIcon />}
                title={i18next.t('errors:default')}
            />
        ),
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:timeline'
        }
    },
    PHOTOSHOOT_REQUEST: {
        path: '/photoshoot-requests',
        element: <pages.PhotoshootRequests />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:dashboard'
        }
    },
    CATEGORIES: {
        path: '/categories',
        element: <pages.Categories />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:dashboard'
        }
    },
    CO_ALARM_DASHBOARD: {
        path: '/co-alarm',
        element: <pages.CoAlarmDashboard />
    },
    CO_ALARM_SEND_REQUEST: {
        path: '/co-alarm/send-request',
        element: <pages.CoAlarmSendRequest />
    },
    CO_ALARM_REQUEST_DETAILS: {
        path: '/co-alarm/:listingId/request/:requestId',
        element: <pages.CoAlarmRequestDetails />
    },
    CO_ALARM_INACTIVE: {
        path: '/co-alarm/inactive',
        element: <pages.CoAlarmInactive />
    },
    TERMS_OF_SERVICE: {
        path: '/terms-of-service/:type',
        element: <pages.TermsOfService />
    },
    NOT_FOUND: {
        path: '*',
        element: <pages.NotFound />
    }
}
