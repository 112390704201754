import {
    ExperiencesVideoBody,
    ExperiencesVideoFooter,
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle,
    ShortClipExampleButton
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesVideoProgressbar} from '@/features/experiences-video/components/experiences-video-progressbar/ExperiencesVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    useExperiencesVideoStore,
    useExperiencesVideoStoreValues
} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {useTranslation} from 'react-i18next'
import {PlayIcon} from '@components/ui/icon'
import {useRef, useState} from 'react'
import {ShortClipModal} from '@/features/experiences-video/components/short-clip-modal/ShortClipModal.tsx'
import {StyledInput} from '@/features/experiences-video/components/preparation-initial-location-step/style.ts'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useUpdateExperiencesVideo} from '@/features/experiences-video/services/useUpdateExperiencesVideo.ts'
import toast from 'react-hot-toast'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'

export const PreparationInitialLocationStep = () => {
    const {t} = useTranslation()
    const inputRef = useRef<HTMLInputElement>(null)
    const setStep = useExperiencesVideoStore(store => store.setStep)
    const initialLocation = useExperiencesVideoStore(store => store.initialLocation)
    const setInitialLocation = useExperiencesVideoStore(state => state.setInitialLocation)
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const [isInputError, setIsInputError] = useState(false)
    const urlParams = useExperiencesVideoUrlParams()
    const storeValues = useExperiencesVideoStoreValues()
    const backExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('preparation_intro'),
            onError: () => toast.error(t('errors:default'))
        }
    })
    const continueExperiencesVideoMutation = useUpdateExperiencesVideo({urlParams})

    const onContinue = async () => {
        const inputValue = inputRef.current?.value

        if (inputValue) {
            try {
                await continueExperiencesVideoMutation.mutateAsync({
                    data: {...storeValues, step: 'preparation_expertises', initialLocation: inputValue}
                })
                setInitialLocation(inputValue)
                setStep('preparation_expertises')
            } catch (error) {
                toast.error(t('errors:default'))
            }
        } else {
            setIsInputError(true)
        }
    }

    return (
        <>
            <ExperiencesVideoBody>
                <Flexbox direction="column" gap={4}>
                    <ExperiencesVideoTitle>
                        {t('experiences_video:preparation_initial_location:title')}
                    </ExperiencesVideoTitle>
                    <ExperiencesVideoParagraph>
                        {t('experiences_video:preparation_initial_location:description')}
                    </ExperiencesVideoParagraph>
                    <ShortClipExampleButton onClick={() => setSlidingModalState('open')}>
                        <PlayIcon />
                        {t('experiences_video:short_clip_example')}
                    </ShortClipExampleButton>
                </Flexbox>
                <StyledInput
                    defaultValue={initialLocation ?? undefined}
                    label={t('commons:location')}
                    type="text"
                    placeholder={t('experiences_video:preparation_initial_location:location_placeholder')}
                    onChange={event => setIsInputError(!event.target.value)}
                    ref={inputRef}
                    errorMessage={isInputError ? t('experiences_video:location_is_required') : undefined}
                />
            </ExperiencesVideoBody>
            <ExperiencesVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                            variant="tertiary"
                            onClick={() =>
                                backExperiencesVideoMutation.mutate({data: {...storeValues, step: 'preparation_intro'}})
                            }
                        >
                            {t('commons:back')}
                            {backExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onContinue}
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:continue')}
                            {continueExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>

            {slidingModalState != 'closed' && (
                <ShortClipModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    src={'https://videos.pexels.com/video-files/27878573/12253181_1440_2560_25fps.mp4'}
                />
            )}
        </>
    )
}
