import {RequirementCard} from '@/features/experiences-video/components/requirement-card/RequirementCard.tsx'
import {ClockStopwatchIcon, Phone02Icon, Repeat02Icon, UserX02Icon} from '@components/ui/icon'
import {useTranslation} from 'react-i18next'
import {StyledCommonRequirements} from '@/features/experiences-video/components/common-requirements/style.ts'

export const CommonRequirements = () => {
    const {t} = useTranslation()

    return (
        <StyledCommonRequirements>
            <RequirementCard icon={<Phone02Icon />}>
                <h3>{t('experiences_video:preparation_requirements:film_vertically_title')}</h3>
                <p>{t('experiences_video:preparation_requirements:film_vertically_description')}</p>
            </RequirementCard>
            <RequirementCard icon={<ClockStopwatchIcon />}>
                <h3>{t('experiences_video:preparation_requirements:film_seconds_clip_title')}</h3>
                <p>{t('experiences_video:preparation_requirements:film_seconds_clip_description')}</p>
            </RequirementCard>
            <RequirementCard icon={<UserX02Icon />}>
                <h3>{t('experiences_video:preparation_requirements:do_not_move_title')}</h3>
                <p>{t('experiences_video:preparation_requirements:do_not_move_description')}</p>
            </RequirementCard>
            <RequirementCard icon={<Repeat02Icon />}>
                <h3>{t('experiences_video:preparation_requirements:ask_actions_title')}</h3>
                <p>{t('experiences_video:preparation_requirements:ask_actions_description')}</p>
            </RequirementCard>
        </StyledCommonRequirements>
    )
}
