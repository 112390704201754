import styled, {css} from 'styled-components'

export const StyledCommonRequirements = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 6}px;
        padding: 0 ${spacing * 4}px;
    `
)

export const StyledRequirementCardWrapper = styled.div(
    ({theme: {spacing}}) => css`
        padding: 0 ${spacing * 4}px;
    `
)
