import styled, {css} from 'styled-components'
import {ExperiencesVideoBody} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

export const StyledPreparationRequirementsStepBody = styled(ExperiencesVideoBody)`
    padding-left: 0;
    padding-right: 0;
`

export const StyledPreparationRequirementsStepTitles = styled.div(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 2}px;
        padding: 0 ${spacing * 4}px;
    `
)
