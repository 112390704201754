import styled, {css} from 'styled-components'
import {ComboboxInput} from '@components/ui/select-atoms/SelectAtoms.tsx'

/*This is needed because we don't have any direct way to do it
 * The solution should be to refactor the InputText
 * */
export const StyledComboboxInput = styled(ComboboxInput)(
    ({theme: {spacing}}) => css`
        & > div > div {
            padding-inline-end: ${spacing * 3}px;
        }
    `
)
