import styled, {css} from 'styled-components'
import {SlidingModal, SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'

export const StyledSlidingModal = styled(SlidingModal)`
    display: grid;
    grid-auto-rows: auto minmax(0, 1fr);
`

export const StyledHeader = styled(SlidingModalHeader)(
    ({theme: {typography, spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4.5}px;

        & h2 {
            padding-left: ${spacing * 2}px;
            ${typography.displayXs};
        }
    `
)
