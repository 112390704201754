import {ExperiencesVideoHeader} from '@/features/experiences-video/components/experiences-video-header/ExperiencesVideoHeader.tsx'
import {ExperiencesVideoStore, useExperiencesVideoStore} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {IntroStep} from '@/features/experiences-video/components/intro-step/IntroStep.tsx'
import {ReactElement, useEffect} from 'react'
import {PreparationIntroStep} from '@/features/experiences-video/components/preparation-intro-step/PreparationIntroStep.tsx'
import {PreparationInitialLocationStep} from '@/features/experiences-video/components/preparation-initial-location-step/PreparationInitialLocationStep.tsx'
import {PreparationExpertisesStep} from '@/features/experiences-video/components/preparation-expertises-step/PreparationExpertisesStep.tsx'
import {PreparationInteractionsStep} from '@/features/experiences-video/components/preparation-interactions-step/PreparationInteractionsStep.tsx'
import {PreparationEmotionsStep} from '@/features/experiences-video/components/preparation-emotions-step/PreparationEmotionsStep.tsx'
import {PreparationDayTimesVenueStep} from '@/features/experiences-video/components/preparation-daytimes-venue-step/PreparationDayTimesVenueStep.tsx'
import {PreparationRecapStep} from '@/features/experiences-video/components/preparation-recap-step/PreparationRecapStep.tsx'
import {PreparationRequirementsStep} from '@/features/experiences-video/components/preparation-requirements-step/PreparationRequirementsStep.tsx'
import {GetReadyShareScheduleStep} from '@/features/experiences-video/components/get-ready-share-schedule-step/GetReadyShareScheduleStep.tsx'
import {GetReadyIntroStep} from '@/features/experiences-video/components/get-ready-intro-step/GetReadyIntroStep.tsx'
import {useExperiencesVideo} from '@/features/experiences-video/services/useExperiencesVideo.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertTriangleIcon} from '@components/ui/icon'
import {useTranslation} from 'react-i18next'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import {ExperiencesVideoPageLayout} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'

export const ExperiencesVideo = () => {
    const {t} = useTranslation()
    const urlParams = useExperiencesVideoUrlParams()
    const query = useExperiencesVideo(urlParams)
    const setStoreValues = useExperiencesVideoStore(state => state.setStoreValues)
    const currentStep = useExperiencesVideoStore(state => state.step)
    const resetStore = useExperiencesVideoStore(state => state.resetStore)
    const stepToComponent = {
        intro: <IntroStep />,
        preparation_intro: <PreparationIntroStep />,
        preparation_initial_location: <PreparationInitialLocationStep />,
        preparation_expertises: <PreparationExpertisesStep />,
        preparation_interactions: <PreparationInteractionsStep />,
        preparation_emotions: <PreparationEmotionsStep />,
        preparation_daytimes_venue: <PreparationDayTimesVenueStep />,
        preparation_recap: <PreparationRecapStep />,
        preparation_requirements: <PreparationRequirementsStep />,
        get_ready_intro: <GetReadyIntroStep />,
        get_ready_share_schedule: <GetReadyShareScheduleStep />
    } as const satisfies Record<ExperiencesVideoStore['step'], ReactElement>

    useEffect(() => {
        if (query.data?.data) {
            setStoreValues(query.data.data)
        } else {
            resetStore()
        }
    }, [query.isSuccess])
    console.log(currentStep)
    return (
        <ExperiencesVideoPageLayout>
            <ExperiencesVideoHeader hasBackButton />
            {query.isPending ? (
                <Spinner size={32} style={{alignSelf: 'center'}} />
            ) : query.isError ? (
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:invalid_url')} />
            ) : (
                stepToComponent[currentStep]
            )}
        </ExperiencesVideoPageLayout>
    )
}
