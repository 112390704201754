import {PauseCircleIcon, PlayIcon, VolumeMaxIcon, VolumeXIcon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {forwardRef, useRef, useState, VideoHTMLAttributes} from 'react'
import {
    StyledLoadingVideoWrapper,
    StyledMuteVideoToggle,
    StyledPlayVideoButton,
    StyledTrailerExampleVideo,
    StyledTrailerExampleVideoDescription,
    StyledTrailerExampleVideoFullScreenGhost,
    StyledTrailerExampleVideoTitle,
    StyledTrailerExampleVideoWrapper
} from '@/features/experiences-video/components/video-trailer-controller/style.ts'
import {VideoState} from '@/features/experiences-video/hooks/useVideoController.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'

interface VideoTrailerController extends VideoHTMLAttributes<HTMLVideoElement> {
    title: string
    description: string
    src: string
    videoState: VideoState
    playPauseToggle: () => void
    volumeToggle: () => void
}

export const VideoTrailerController = forwardRef<HTMLVideoElement, VideoTrailerController>(
    ({title, description, src, videoState, volumeToggle, playPauseToggle, ...rest}, ref) => {
        const [isFullScreen, setIsFullScreen] = useState(false)
        const wrapperRef = useRef<HTMLDivElement>(null)

        return (
            <Flexbox width={'100%'} direction={'column'} gap={4}>
                {isFullScreen && <StyledTrailerExampleVideoFullScreenGhost />}
                <StyledTrailerExampleVideoWrapper ref={wrapperRef} $isFullscreen={isFullScreen}>
                    <StyledTrailerExampleVideo
                        onClick={() => setIsFullScreen(!isFullScreen)}
                        preload={'metadata'}
                        controls={false}
                        playsInline
                        disableRemotePlayback
                        ref={ref}
                        loop
                        disablePictureInPicture
                        {...rest}
                    >
                        <source src={src} />
                    </StyledTrailerExampleVideo>
                    {videoState == 'loading' && (
                        <StyledLoadingVideoWrapper>
                            <Spinner size={30} />
                        </StyledLoadingVideoWrapper>
                    )}
                    <StyledMuteVideoToggle onClick={volumeToggle}>
                        {rest.muted ? <VolumeXIcon size={20} /> : <VolumeMaxIcon size={20} />}
                    </StyledMuteVideoToggle>
                    <StyledPlayVideoButton onClick={playPauseToggle}>
                        {videoState == 'playing' ? <PauseCircleIcon size={32} /> : <PlayIcon size={32} />}
                    </StyledPlayVideoButton>
                </StyledTrailerExampleVideoWrapper>
                <Flexbox direction={'column'} gap={1}>
                    <StyledTrailerExampleVideoTitle>{title}</StyledTrailerExampleVideoTitle>
                    <StyledTrailerExampleVideoDescription>{description}</StyledTrailerExampleVideoDescription>
                </Flexbox>
            </Flexbox>
        )
    }
)
