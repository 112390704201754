import {
    SelectItemProps,
    SelectPopoverProps,
    Role,
    Select,
    useSelectContext,
    Combobox,
    ComboboxProps,
    ComboboxListProps,
    ComboboxPopoverProps,
    ComboboxItemProps,
    SelectListProps
} from '@ariakit/react'
import {
    StyledComboboxItem,
    StyledComboboxPopover,
    StyledSelectArrow,
    StyledSelectComboboxList,
    StyledSelectComboboxPopover,
    StyledSelectItem,
    StyledSelectList,
    StyledSelectPopover,
    StyledSelectTrigger
} from '@components/ui/select-atoms/style'
import {forwardRef, ReactNode} from 'react'
import {ButtonProps} from '@components/ui/button-legacy/Button.tsx'
import {ChevronDownIcon} from '@components/ui/icon'
import {raise} from '@utilities/helpers.ts'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import InputText, {InputProps} from '@components/commons/input-text/InputText.tsx'

/*Popover*/
export const SelectPopover = (props: SelectPopoverProps) => <StyledSelectPopover gutter={4} sameWidth {...props} />

export const ComboboxPopover = (props: ComboboxPopoverProps) => (
    <StyledComboboxPopover gutter={4} sameWidth {...props} />
)

export const SelectComboboxPopover = (props: SelectPopoverProps) => (
    <StyledSelectComboboxPopover gutter={4} sameWidth {...props} />
)

/*List*/
export const SelectList = (props: SelectListProps) => (
    <ScrollArea scrollbar={<Scrollbar />}>
        <StyledSelectList {...props} />
    </ScrollArea>
)

export const SelectComboboxList = (props: ComboboxListProps) => (
    <ScrollArea scrollbar={<Scrollbar />}>
        <StyledSelectComboboxList {...props} />
    </ScrollArea>
)

export const ComboboxList = SelectComboboxList

/*Item*/
export const SelectItem = (props: SelectItemProps) => <StyledSelectItem {...props} />
export const ComboboxItem = (props: ComboboxItemProps) => <StyledComboboxItem {...props} />

/*input*/
export const ComboboxInput = (props: ComboboxProps & Omit<InputProps, 'type'>) => (
    <Combobox autoSelect render={<InputText type="text" inputSize="sm" />} {...props} />
)

/*Trigger*/
interface SelectTriggerProps extends Omit<ButtonProps, 'children' | 'placeholder'> {
    placeholder?: ReactNode
    displayValue: (value: string | string[]) => ReactNode
}

export const SelectTrigger = forwardRef<HTMLSelectElement, SelectTriggerProps>(
    ({placeholder, displayValue, ...rest}, ref) => {
        const selectContext = useSelectContext() ?? raise('SelectTrigger must be in a SelectProvider')
        const value = selectContext.useState('value')
        const isOpen = selectContext.useState('open')
        const children = value.length >= 1 ? displayValue(value) : undefined

        return (
            <Select
                render={
                    <Role.select
                        ref={ref}
                        render={
                            <StyledSelectTrigger $hasChildren={!!children} type="button" variant="secondary" {...rest}>
                                {children || placeholder}
                                <StyledSelectArrow $isOpen={isOpen} render={<ChevronDownIcon />} />
                            </StyledSelectTrigger>
                        }
                    />
                }
            />
        )
    }
)
