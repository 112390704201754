import {create} from 'zustand'

export interface ExperiencesVideoQuizStoreValues {
    step: 'phone' | 'upload' | 'hold_camera' | 'staged_people_count' | 'elements'
    phoneAnswer: 'vertically' | 'horizontally' | null
    uploadAnswer: '1_edited_video' | '20_40_short_clips' | '5_photos' | null
    holdCameraAnswer: 'my_self' | 'friend' | null
    stagedPeopleCountAnswer: 'none' | 'at_least_3' | null
    elementsAnswer: 'combination' | 'do_not_move' | 'do_not_zoom' | 'all' | null
}

export interface ExperiencesVideoQuizStore extends ExperiencesVideoQuizStoreValues {
    userType: 'friend' | 'host'
    setUserType: (step: ExperiencesVideoQuizStore['userType']) => void
    setStep: (step: ExperiencesVideoQuizStore['step']) => void
    setPhoneAnswer: (phoneAnswer: ExperiencesVideoQuizStore['phoneAnswer']) => void
    setUploadAnswer: (uploadAnswer: ExperiencesVideoQuizStore['uploadAnswer']) => void
    setHoldCameraAnswer: (holdCameraAnswer: ExperiencesVideoQuizStore['holdCameraAnswer']) => void
    setStagedPeopleCountAnswer: (stagedPeopleCountAnswer: ExperiencesVideoQuizStore['stagedPeopleCountAnswer']) => void
    setElementsAnswer: (elementsAnswer: ExperiencesVideoQuizStore['elementsAnswer']) => void
    resetValues: () => void
}

const initialValues = {
    step: 'phone',
    phoneAnswer: null,
    uploadAnswer: null,
    holdCameraAnswer: null,
    stagedPeopleCountAnswer: null,
    elementsAnswer: null
} as const satisfies ExperiencesVideoQuizStoreValues

export const useExperiencesVideoQuizStore = create<ExperiencesVideoQuizStore>()(set => ({
    ...initialValues,
    userType: 'host',
    setUserType: userType => set({userType}),
    setStep: step => set({step}),
    setPhoneAnswer: phoneAnswer => set({phoneAnswer}),
    setUploadAnswer: uploadAnswer => set({uploadAnswer}),
    setHoldCameraAnswer: holdCameraAnswer => set({holdCameraAnswer}),
    setStagedPeopleCountAnswer: stagedPeopleCountAnswer => set({stagedPeopleCountAnswer}),
    setElementsAnswer: elementsAnswer => set({elementsAnswer}),
    resetValues: () => set({...initialValues})
}))
