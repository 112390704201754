import {
    ExperiencesVideoBody,
    ExperiencesVideoFooter,
    ExperiencesVideoLabel,
    ExperiencesVideoTitle,
    ShortClipExampleButton
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesVideoProgressbar} from '@/features/experiences-video/components/experiences-video-progressbar/ExperiencesVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {
    useExperiencesVideoStore,
    useExperiencesVideoStoreValues
} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {z} from 'zod'
import {ExperiencesVideoDayTime, ExperiencesVideoVenue} from '@/features/experiences-video/types.ts'
import i18n from '@/translations/i18n.ts'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {raise} from '@utilities/helpers.ts'
import {PlayIcon} from '@components/ui/icon'
import {useState} from 'react'
import {ShortClipModal} from '@/features/experiences-video/components/short-clip-modal/ShortClipModal.tsx'
import {Label} from '@components/ui/label/Label.tsx'
import {LocationSelect} from '@/features/experiences-video/components/location-select/LocationSelect.tsx'
import {ErrorMessage} from '@components/ui/error-message/ErrorMessage.tsx'
import {StyledEmotionsOptions} from '@/features/experiences-video/components/preparation-daytimes-venue-step/style.ts'
import {ExperiencesVideoAnswerItem} from '@/features/experiences-video/components/experiences-video-answer-item/ExperiencesVideoAnswerItem.tsx'
import {
    experienceVideoDayTimeToIcon,
    experienceVideoDayTimeToLabelTranslationKey,
    experienceVideoVenueToLabelTranslationKey
} from '@/features/experiences-video/utils.tsx'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import {useUpdateExperiencesVideo} from '@/features/experiences-video/services/useUpdateExperiencesVideo.ts'
import toast from 'react-hot-toast'
import Spinner from '@components/ui/spinner/Spinner.tsx'

const ValidationSchema = z.object({
    dayTimes: z
        .array(ExperiencesVideoDayTime)
        .min(1, {message: i18n.t('experiences_video:min_options_error', {count: 1})}),
    venue: ExperiencesVideoVenue,
    dayTimesVenueLocation: z.string().min(1, {message: i18n.t('experiences_video:location_is_required')})
})
type ValidationSchema = z.infer<typeof ValidationSchema>

export const PreparationDayTimesVenueStep = () => {
    const {t} = useTranslation()
    const setStep = useExperiencesVideoStore(store => store.setStep)
    const dayTimesVenueLocation = useExperiencesVideoStore(store => store.dayTimesVenueLocation)
    const emotionsLocation =
        useExperiencesVideoStore(store => store.emotionsLocation) ?? raise('emotionsLocation is nullish')
    const interactionsLocation =
        useExperiencesVideoStore(store => store.interactionsLocation) ?? raise('interactionsLocation is nullish')
    const expertisesLocation =
        useExperiencesVideoStore(store => store.expertisesLocation) ?? raise('expertisesLocation is nullish')
    const initialLocation =
        useExperiencesVideoStore(store => store.initialLocation) ?? raise('initialLocation is nullish')
    const dayTimes = useExperiencesVideoStore(store => store.dayTimes)
    const setDayTimes = useExperiencesVideoStore(store => store.setDayTimes)
    const venue = useExperiencesVideoStore(store => store.venue)
    const setVenue = useExperiencesVideoStore(store => store.setVenue)
    const setDayTimesVenueLocation = useExperiencesVideoStore(store => store.setDayTimesVenueLocation)
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const form = useForm<ValidationSchema>({
        defaultValues: {
            dayTimes,
            venue: venue ?? undefined,
            dayTimesVenueLocation: dayTimesVenueLocation ?? emotionsLocation
        },
        resolver: zodResolver(ValidationSchema)
    })
    const urlParams = useExperiencesVideoUrlParams()
    const storeValues = useExperiencesVideoStoreValues()
    const backExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('preparation_emotions'),
            onError: () => toast.error(t('errors:default'))
        }
    })
    const continueExperiencesVideoMutation = useUpdateExperiencesVideo({urlParams})

    const onContinue = form.handleSubmit(async formValues => {
        try {
            await continueExperiencesVideoMutation.mutateAsync({
                data: {
                    ...storeValues,
                    step: 'preparation_recap',
                    dayTimes: formValues.dayTimes,
                    venue: formValues.venue,
                    dayTimesVenueLocation: formValues.dayTimesVenueLocation
                }
            })
            setDayTimes(formValues.dayTimes)
            setVenue(formValues.venue)
            setDayTimesVenueLocation(formValues.dayTimesVenueLocation)
            setStep('preparation_recap')
        } catch (error) {
            toast.error(t('errors:default'))
        }
    })

    return (
        <>
            <ExperiencesVideoBody>
                <Flexbox direction="column" gap={4}>
                    <ExperiencesVideoTitle>
                        {t('experiences_video:preparation_daytimes_venue:title')}
                    </ExperiencesVideoTitle>
                    <ShortClipExampleButton onClick={() => setSlidingModalState('open')}>
                        <PlayIcon />
                        {t('experiences_video:short_clip_example')}
                    </ShortClipExampleButton>
                </Flexbox>
                <Flexbox direction="column" gap={4} width="100%">
                    <ExperiencesVideoLabel>
                        {t('experiences_video:preparation_daytimes_venue:day_times_label')}
                    </ExperiencesVideoLabel>
                    <StyledEmotionsOptions>
                        {ExperiencesVideoDayTime.options.map(dayTime => (
                            <ExperiencesVideoAnswerItem
                                {...form.register('dayTimes')}
                                value={dayTime}
                                type="checkbox"
                                label={
                                    <Flexbox direction="column" gap={2}>
                                        {experienceVideoDayTimeToIcon[dayTime]}
                                        {t(experienceVideoDayTimeToLabelTranslationKey[dayTime])}
                                    </Flexbox>
                                }
                                key={dayTime}
                            />
                        ))}
                    </StyledEmotionsOptions>
                    {!!form.formState.errors.dayTimes && (
                        <ErrorMessage>{form.formState.errors.dayTimes.message}</ErrorMessage>
                    )}
                </Flexbox>
                <Flexbox direction="column" gap={4} width="100%">
                    <ExperiencesVideoLabel>
                        {t('experiences_video:preparation_daytimes_venue:venue_label')}
                    </ExperiencesVideoLabel>
                    <Flexbox direction="column" gap={3} width="100%" align="stretch">
                        {ExperiencesVideoVenue.options.map(venue => (
                            <ExperiencesVideoAnswerItem
                                {...form.register('venue')}
                                value={venue}
                                type="radio"
                                label={t(experienceVideoVenueToLabelTranslationKey[venue])}
                                key={venue}
                            />
                        ))}
                    </Flexbox>
                    {!!form.formState.errors.venue && (
                        <ErrorMessage>{t('experiences_video:preparation_daytimes_venue:venue_required')}</ErrorMessage>
                    )}
                </Flexbox>
                <Flexbox direction="column" gap={1.5} width="100%" align="stretch">
                    <Label>{t('experiences_video:preparation_daytimes_venue:location_select_label')}</Label>
                    <Controller
                        control={form.control}
                        name="dayTimesVenueLocation"
                        render={({field}) => (
                            <LocationSelect
                                value={field.value}
                                //set needed because the locations can be the duplicated
                                options={[
                                    ...new Set([
                                        emotionsLocation,
                                        interactionsLocation,
                                        expertisesLocation,
                                        initialLocation
                                    ])
                                ]}
                                onSelectOption={field.onChange}
                            />
                        )}
                    />
                    {!!form.formState.errors.dayTimesVenueLocation && (
                        <ErrorMessage>{form.formState.errors.dayTimesVenueLocation.message}</ErrorMessage>
                    )}
                </Flexbox>
            </ExperiencesVideoBody>
            <ExperiencesVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            variant="tertiary"
                            onClick={() =>
                                backExperiencesVideoMutation.mutate({
                                    data: {...storeValues, step: 'preparation_emotions'}
                                })
                            }
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:back')}
                            {backExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={onContinue}
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                        >
                            {t('commons:continue')}
                            {continueExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>

            {slidingModalState != 'closed' && (
                <ShortClipModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    src={'https://videos.pexels.com/video-files/27878573/12253181_1440_2560_25fps.mp4'}
                />
            )}
        </>
    )
}
