import {
    ExperiencesVideoBody,
    ExperiencesVideoFooter,
    ExperiencesVideoLabel,
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle,
    ShortClipExampleButton
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {PlayIcon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    useExperiencesVideoStore,
    useExperiencesVideoStoreValues
} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {ExperiencesVideoProgressbar} from '@/features/experiences-video/components/experiences-video-progressbar/ExperiencesVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ShortClipModal} from '@/features/experiences-video/components/short-clip-modal/ShortClipModal.tsx'
import {ExperiencesVideoAnswerItem} from '@/features/experiences-video/components/experiences-video-answer-item/ExperiencesVideoAnswerItem.tsx'
import {ErrorMessage} from '@components/ui/error-message/ErrorMessage.tsx'
import {Label} from '@components/ui/label/Label.tsx'
import {LocationSelect} from '@/features/experiences-video/components/location-select/LocationSelect.tsx'
import {raise} from '@utilities/helpers.ts'
import {z} from 'zod'
import {ExperiencesVideoExpertise} from '@/features/experiences-video/types.ts'
import {Controller, useForm, useWatch} from 'react-hook-form'
import i18n from '@/translations/i18n.ts'
import {zodResolver} from '@hookform/resolvers/zod'
import {experienceVideoExpertiseToLabelTranslationKey} from '@/features/experiences-video/utils.tsx'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import {useUpdateExperiencesVideo} from '@/features/experiences-video/services/useUpdateExperiencesVideo.ts'
import toast from 'react-hot-toast'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import firstVideoPoster from '@assets/images/experiences-video/first_video_poster.webp'

const ValidationSchema = z.object({
    expertises: z
        .array(ExperiencesVideoExpertise)
        .min(1, {message: i18n.t('experiences_video:min_options_error', {count: 1})})
        .max(2, {message: i18n.t('experiences_video:max_options_error', {count: 2})}),
    expertisesLocation: z.string().min(1, {message: i18n.t('experiences_video:location_is_required')})
})
type ValidationSchema = z.infer<typeof ValidationSchema>

export const PreparationExpertisesStep = () => {
    const {t} = useTranslation()
    const initialLocation =
        useExperiencesVideoStore(store => store.initialLocation) ?? raise('initialLocation is nullish')
    const setExpertisesLocation = useExperiencesVideoStore(store => store.setExpertisesLocation)
    const expertisesLocation = useExperiencesVideoStore(store => store.expertisesLocation)
    const expertises = useExperiencesVideoStore(store => store.expertises)
    const setStep = useExperiencesVideoStore(store => store.setStep)
    const setExpertises = useExperiencesVideoStore(store => store.setExpertises)
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const urlParams = useExperiencesVideoUrlParams()
    const storeValues = useExperiencesVideoStoreValues()
    const form = useForm<ValidationSchema>({
        defaultValues: {
            expertises,
            expertisesLocation: expertisesLocation ?? initialLocation
        },
        resolver: zodResolver(ValidationSchema)
    })
    const expertisesWatch = useWatch({name: 'expertises', control: form.control})
    const backExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('preparation_initial_location'),
            onError: () => toast.error(t('errors:default'))
        }
    })
    const continueExperiencesVideoMutation = useUpdateExperiencesVideo({urlParams})

    const onContinue = form.handleSubmit(async formValues => {
        try {
            await continueExperiencesVideoMutation.mutateAsync({
                data: {...storeValues, step: 'preparation_interactions', ...formValues}
            })
            setExpertises(formValues.expertises)
            setExpertisesLocation(formValues.expertisesLocation)
            setStep('preparation_interactions')
        } catch (error) {
            toast.error(t('errors:default'))
        }
    })

    return (
        <>
            <ExperiencesVideoBody>
                <Flexbox direction="column" gap={4}>
                    <ExperiencesVideoTitle>{t('experiences_video:preparation_expertises:title')}</ExperiencesVideoTitle>
                    <ExperiencesVideoParagraph>
                        {t('experiences_video:preparation_expertises:description')}
                    </ExperiencesVideoParagraph>
                    <ShortClipExampleButton onClick={() => setSlidingModalState('open')}>
                        <PlayIcon />
                        {t('experiences_video:short_clip_example')}
                    </ShortClipExampleButton>
                </Flexbox>
                <Flexbox direction="column" gap={4} width="100%">
                    <ExperiencesVideoLabel>
                        {t('experiences_video:select_up_to_x_options', {count: 2})}
                    </ExperiencesVideoLabel>
                    <Flexbox direction="column" gap={3} align="stretch" width="100%">
                        {ExperiencesVideoExpertise.options.map(expertise => (
                            <ExperiencesVideoAnswerItem
                                disabled={expertisesWatch.length == 2 && !expertisesWatch.includes(expertise)}
                                {...form.register('expertises')}
                                value={expertise}
                                label={t(experienceVideoExpertiseToLabelTranslationKey[expertise])}
                                type={'checkbox'}
                                key={expertise}
                            />
                        ))}
                        {form.formState.errors.expertises && (
                            <ErrorMessage>{form.formState.errors.expertises.message}</ErrorMessage>
                        )}
                    </Flexbox>
                </Flexbox>
                <Flexbox direction="column" gap={1.5} width="100%" align="stretch">
                    <Label>{t('experiences_video:preparation_expertises:location_select_label')}</Label>
                    <Controller
                        control={form.control}
                        name="expertisesLocation"
                        render={({field}) => (
                            <LocationSelect
                                value={field.value}
                                options={[initialLocation]}
                                onSelectOption={field.onChange}
                            />
                        )}
                    />
                    {form.formState.errors.expertisesLocation && (
                        <ErrorMessage>{form.formState.errors.expertisesLocation.message}</ErrorMessage>
                    )}
                </Flexbox>
            </ExperiencesVideoBody>
            <ExperiencesVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                            variant="tertiary"
                            onClick={() =>
                                backExperiencesVideoMutation.mutate({
                                    data: {...storeValues, step: 'preparation_initial_location'}
                                })
                            }
                        >
                            {t('commons:back')}
                            {backExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            disabled={
                                backExperiencesVideoMutation.isPending || continueExperiencesVideoMutation.isPending
                            }
                            variant="primary"
                            onClick={onContinue}
                        >
                            {t('commons:continue')}
                            {continueExperiencesVideoMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>

            {slidingModalState != 'closed' && (
                <ShortClipModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    src={'https://stream.media.muscache.com/a02YucAcdLYE02Xy3jZLv98XMGiTUaflVCYgLefzMJhWY.mp4'}
                    previewImgSrc={firstVideoPoster}
                />
            )}
        </>
    )
}
