import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
export const StyledExperiencesVideoContentMaxWidth = 600
export const StyledExperiencesVideoPageLayout = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: minmax(0, 1fr);
    height: 100%;
`

export const StyledExperiencesVideoContent = styled.div`
    width: 100%;
    height: 100%;
    max-width: ${StyledExperiencesVideoContentMaxWidth}px;
    margin: 0 auto;
`

export const StyledExperiencesVideoTitle = styled.h1(
    ({theme: {palette, typography}}) => css`
        ${typography.displaySm};
        font-weight: 500;
        color: ${palette.neutral[900]};
    `
)

export const StyledExperiencesVideoParagraph = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[900]};
    `
)

export const StyledExperiencesVideoBody = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        gap: ${spacing * 8}px;
        flex-direction: column;
        padding: ${spacing * 6}px ${spacing * 4}px;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    `
)

export const StyledExperiencesVideoModalBody = styled(StyledExperiencesVideoBody)(
    () => css`
        max-height: 100%;
        overflow-y: auto;
    `
)

export const StyledExperiencesVideoFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        position: sticky;
        bottom: 0;
        padding: ${spacing * 2}px ${spacing * 4}px ${spacing * 6}px ${spacing * 4}px;
        background-color: ${palette.neutral.white};
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    `
)

export const StyledShortClipExampleButton = styled(Button)`
    border-radius: 18px;
`

export const StyledExperiencesVideoLabel = styled.label(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        color: ${palette.neutral['900']};
    `
)

export const StyledExperiencesVideoActionCard = styled.button<{$isCompleted?: boolean}>(
    ({theme: {palette, spacing, typography}, $isCompleted}) => css`
        width: 100%;
        display: flex;
        gap: ${spacing * 3}px;
        padding: ${spacing * 4}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 12px;
        background-color: ${$isCompleted ? palette.success['50'] : palette.neutral.white};
        color: ${palette.neutral['700']};
        cursor: pointer;

        & svg {
            width: 28px;
            height: 28px;
            fill: ${$isCompleted ? palette.success['700'] : palette.neutral.black};
        }
        & h3 {
            ${typography.textMd};
            font-weight: 500;
        }
        & p {
            ${typography.textSm};
        }
    `
)
