import {z} from 'zod'
import {steps} from '@/features/experiences-video/utils.tsx'

export const ExperiencesVideoExpertise = z.enum([
    'equipment',
    'technical_action',
    'speaking',
    'showing_surroundings',
    'other'
])
export type ExperiencesVideoExpertise = z.infer<typeof ExperiencesVideoExpertise>

export const ExperiencesVideoEmotions = z.enum([
    'joy',
    'fear',
    'surprise',
    'friendship',
    'curiosity',
    'focus',
    'excitement',
    'relaxation'
])
export type ExperiencesVideoEmotions = z.infer<typeof ExperiencesVideoEmotions>

export const ExperiencesVideoDayTime = z.enum(['sunrise', 'morning', 'sunset', 'afternoon'])
export type ExperiencesVideoDayTime = z.infer<typeof ExperiencesVideoDayTime>

export const ExperiencesVideoVenue = z.enum(['restaurant_bar', 'public_venue_business', 'private_home', 'outdoor'])
export type ExperiencesVideoVenue = z.infer<typeof ExperiencesVideoVenue>

export const HostExperienceProposalStatus = z.enum(['pending', 'in_progress'])
export type HostExperienceProposalStatus = z.infer<typeof HostExperienceProposalStatus>

/**
 * Host experience proposal response schema
 */
export const HostExperienceProposal = z.object({
    id: z.number(),
    title: z.string(),
    address: z.string(),
    host_id: z.coerce.number().int(),
    status: HostExperienceProposalStatus,
    external_host_id: z.coerce.number().int().nullable(),
    completed_at: z.coerce.date().nullable(),
    created_at: z.coerce.date().nullable()
})
export type HostExperienceProposal = z.infer<typeof HostExperienceProposal>

export const ExperiencesVideo = z.object({
    data: z
        .object({
            //TODO: improve the 'step' type in order to get automatically the new main steps added on the 'steps' util
            step: z.enum([...steps.intro, ...steps.get_ready, ...steps.preparation, ...steps.upload]),
            initialLocation: z.string().min(1).nullable(),
            expertises: z.array(ExperiencesVideoExpertise),
            expertisesLocation: z.string().min(1).nullable(),
            doNotInteract: z.boolean(),
            interactions: z.array(z.string()),
            interactionsLocation: z.string().min(1).nullable(),
            emotionsNotSure: z.boolean(),
            emotions: z.array(ExperiencesVideoEmotions),
            emotionsLocation: z.string().min(1).nullable(),
            dayTimes: z.array(ExperiencesVideoDayTime),
            venue: ExperiencesVideoVenue.nullable(),
            dayTimesVenueLocation: z.string().min(1).nullable()
        })
        .nullable()
})
export type ExperiencesVideo = z.infer<typeof ExperiencesVideo>

export const ExperiencesVideoUrlParams = z.object({
    hostCode: z.coerce.number().int(),
    hostPassword: z.string(),
    experiencesVideoId: z.coerce.number().int()
})
export type ExperiencesVideoUrlParams = z.infer<typeof ExperiencesVideoUrlParams>
