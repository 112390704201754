import {
    ExperiencesVideoActionCard,
    ExperiencesVideoBody,
    ExperiencesVideoFooter,
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {AnnotationQuestionIcon, CheckIcon, Share05Icon} from '@components/ui/icon'
import {QuizModal} from '@/features/experiences-video/components/quiz-modal/QuizModal.tsx'
import {useState} from 'react'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {ExperiencesVideoProgressbar} from '@/features/experiences-video/components/experiences-video-progressbar/ExperiencesVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {
    useExperiencesVideoStore,
    useExperiencesVideoStoreValues
} from '@/features/experiences-video/stores/experiencesVideo.ts'
import {copyTextToClipboard} from '@utilities/helpers.ts'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {useExperiencesVideoUrlParams} from '@/features/experiences-video/hooks/useExperiencesVideoUrlParams.ts'
import {useUpdateExperiencesVideo} from '@/features/experiences-video/services/useUpdateExperiencesVideo.ts'
import toast from 'react-hot-toast'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useExperiencesVideoQuizStore} from '@/features/experiences-video/stores/experiencesVideoQuiz.ts'

export const GetReadyShareScheduleStep = () => {
    const {t} = useTranslation()
    const setStep = useExperiencesVideoStore(store => store.setStep)
    const resetQuiz = useExperiencesVideoQuizStore(store => store.resetValues)
    const setUserType = useExperiencesVideoQuizStore(store => store.setUserType)
    const [isCopied, setIsCopied] = useState(false)
    const [quizSlidingModalState, setQuizSlidingModalState] = useState<SlidingModalState>('closed')
    const urlParams = useExperiencesVideoUrlParams()
    const storeValues = useExperiencesVideoStoreValues()
    const elementsAnswer = useExperiencesVideoQuizStore(store => store.elementsAnswer)
    const backExperiencesVideoMutation = useUpdateExperiencesVideo({
        urlParams,
        options: {
            onSuccess: () => setStep('get_ready_intro'),
            onError: () => toast.error(t('errors:default'))
        }
    })

    const copyToClipboard = async () => {
        await copyTextToClipboard(
            `${window.location.origin}${generatePath(routes.EXPERIENCE_VIDEO_HOST_FRIEND.path, urlParams)}`
        )
        setIsCopied(true)
    }

    return (
        <>
            <ExperiencesVideoBody>
                <Flexbox direction="column" gap={2}>
                    <ExperiencesVideoTitle>{t('experiences_video:get_ready_to_film')}</ExperiencesVideoTitle>
                    <ExperiencesVideoParagraph>
                        {t('experiences_video:get_ready_share_schedule:description')}
                    </ExperiencesVideoParagraph>
                </Flexbox>
                <Flexbox direction="column" gap={3}>
                    <ExperiencesVideoActionCard isCompleted={isCopied} onClick={copyToClipboard}>
                        {isCopied ? <CheckIcon /> : <Share05Icon />}
                        <Flexbox direction="column" gap={2}>
                            <h3>{t('experiences_video:get_ready_share_schedule:share_resources_title')}</h3>
                            <p>{t('experiences_video:get_ready_share_schedule:share_resources_description')}</p>
                        </Flexbox>
                    </ExperiencesVideoActionCard>
                    <ExperiencesVideoActionCard
                        isCompleted={!!elementsAnswer}
                        onClick={() => {
                            resetQuiz()
                            setUserType('host')
                            setQuizSlidingModalState('open')
                        }}
                    >
                        {elementsAnswer ? <CheckIcon /> : <AnnotationQuestionIcon />}
                        <Flexbox direction="column" gap={2}>
                            <h3>{t('experiences_video:take_a_quiz')}</h3>
                            <p>{t('experiences_video:take_a_quiz_description')}</p>
                        </Flexbox>
                    </ExperiencesVideoActionCard>
                </Flexbox>

                {quizSlidingModalState != 'closed' && (
                    <QuizModal
                        slidingModalState={quizSlidingModalState}
                        setSlidingModalState={setQuizSlidingModalState}
                    />
                )}
            </ExperiencesVideoBody>
            <ExperiencesVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesVideoProgressbar />
                    <Button
                        variant="tertiary"
                        onClick={() =>
                            backExperiencesVideoMutation.mutate({data: {...storeValues, step: 'get_ready_intro'}})
                        }
                        disabled={backExperiencesVideoMutation.isPending}
                    >
                        {t('commons:back')}
                        {backExperiencesVideoMutation.isPending && <Spinner />}
                    </Button>
                </Flexbox>
            </ExperiencesVideoFooter>
        </>
    )
}
