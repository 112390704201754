import {
    StyledExperiencesVideoHeaderButton,
    StyledExperiencesVideoHeaderWrapper
} from '@/features/experiences-video/components/experiences-video-header/style.ts'
import {LangSelectionModal} from '@components/commons/lang-selection-modal/LangSelectionModal.tsx'
import {FC, useState} from 'react'
import {ChangeLangModalTrigger} from '@components/commons/change-lang-modal-trigger/ChangeLangModalTrigger.tsx'
import {AlertCircleIcon, ChevronLeftIcon} from '@components/ui/icon'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'

export const ExperiencesVideoHeader: FC<{hasBackButton?: boolean}> = ({hasBackButton}) => {
    const {t} = useTranslation()
    const params = useURLParsedParams(
        z.object({
            hostCode: z.coerce.number().int(),
            hostPassword: z.string()
        })
    )
    const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(false)

    if (!params.success) {
        return <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
    }

    return (
        <StyledExperiencesVideoHeaderWrapper align={'center'} justify={hasBackButton ? 'space-between' : 'flex-end'}>
            {isLanguageModalVisible && <LangSelectionModal onCloseCb={() => setIsLanguageModalVisible(false)} />}
            {hasBackButton && (
                <StyledExperiencesVideoHeaderButton
                    to={generatePath(routes.DASHBOARD.path, params.data)}
                    size={'sm'}
                    variant={'tertiary'}
                >
                    <ChevronLeftIcon />
                    {t('commons:dashboard')}
                </StyledExperiencesVideoHeaderButton>
            )}
            <ChangeLangModalTrigger showCallback={() => setIsLanguageModalVisible(true)} />
        </StyledExperiencesVideoHeaderWrapper>
    )
}
