import {SlidingModalCloseButton, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {FC, ReactElement} from 'react'
import {StyledQuizModal, StyledModalHeader} from '@/features/experiences-video/components/quiz-modal/style.ts'
import {useTranslation} from 'react-i18next'
import {
    ExperiencesVideoQuizStore,
    useExperiencesVideoQuizStore
} from '@/features/experiences-video/stores/experiencesVideoQuiz.ts'
import {QuizModalPhoneStep} from '@/features/experiences-video/components/quiz-modal-phone-step/QuizModalPhoneStep.tsx'
import {QuizModalUploadStep} from '@/features/experiences-video/components/quiz-modal-upload-step/QuizModalUploadStep.tsx'
import {QuizModalHoldCameraStep} from '@/features/experiences-video/components/quiz-modal-hold-camera-step/QuizModalHoldCameraStep.tsx'
import {QuizModalStagedPeopleCountStep} from '@/features/experiences-video/components/quiz-modal-staged-people-count-step/QuizModalStagedPeopleCountStep.tsx'
import {QuizModalElementsStep} from '@/features/experiences-video/components/quiz-modal-elements-step/QuizModalElementsStep.tsx'

interface QuizModalProps {
    slidingModalState: SlidingModalState
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const QuizModal: FC<QuizModalProps> = ({slidingModalState, setSlidingModalState}) => {
    const {t} = useTranslation()
    const step = useExperiencesVideoQuizStore(store => store.step)

    const stepToComponent = {
        phone: <QuizModalPhoneStep />,
        upload: <QuizModalUploadStep />,
        hold_camera: <QuizModalHoldCameraStep />,
        staged_people_count: <QuizModalStagedPeopleCountStep />,
        elements: <QuizModalElementsStep setSlidingModalState={setSlidingModalState} />
    } as const satisfies Record<ExperiencesVideoQuizStore['step'], ReactElement>

    return (
        <StyledQuizModal slidingModalState={slidingModalState}>
            <StyledModalHeader>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                {t('experiences_video:host_friend_get_ready:quiz:title')}
            </StyledModalHeader>
            {stepToComponent[step]}
        </StyledQuizModal>
    )
}
