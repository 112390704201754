import styled, {css} from 'styled-components'
import {SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'

export const StyledHostFriendRequirementsModalShootlistStep = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
`

export const StyledTitle = styled(SlidingModalHeader)(
    ({theme: {typography, spacing}}) => css`
        padding-left: ${spacing * 4}px;
        padding-top: ${spacing * 16}px;
        ${typography.displayXs};
    `
)
