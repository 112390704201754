import {FC, ReactNode, useState} from 'react'
import {SlidingModal, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {HostFriendRequirementsModalIntroStep} from '@/features/experiences-video/components/host-friend-requirements-modal-intro-step/HostFriendRequirementsModalIntroStep.tsx'
import {HostFriendRequirementsModalTechnicalStep} from '@/features/experiences-video/components/host-friend-requirements-modal-technical-step/HostFriendRequirementsModalTechnicalStep.tsx'
import {HostFriendRequirementsModalPerspectiveStep} from '@/features/experiences-video/components/host-friend-requirements-modal-prespective-step/HostFriendRequirementsModalPerspectiveStep.tsx'
import {HostFriendRequirementsModalShootlistStep} from '@/features/experiences-video/components/host-friend-requirements-modal-shootlist-step/HostFriendRequirementsModalShootlistStep.tsx'

export type RequirementsModalStep = 'intro' | 'technical' | 'perspective' | 'shootList'

interface HostFriendRequirementsModalProps {
    slidingModalState: SlidingModalState
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const HostFriendRequirementsModal: FC<HostFriendRequirementsModalProps> = ({
    slidingModalState,
    setSlidingModalState
}) => {
    const [step, setStep] = useState<RequirementsModalStep>('intro')

    const stepToComponent = {
        intro: <HostFriendRequirementsModalIntroStep setStep={setStep} />,
        technical: <HostFriendRequirementsModalTechnicalStep setStep={setStep} />,
        perspective: <HostFriendRequirementsModalPerspectiveStep setStep={setStep} />,
        shootList: (
            <HostFriendRequirementsModalShootlistStep setStep={setStep} setSlidingModalState={setSlidingModalState} />
        )
    } as const satisfies Record<RequirementsModalStep, ReactNode>

    return <SlidingModal slidingModalState={slidingModalState}>{stepToComponent[step]}</SlidingModal>
}
