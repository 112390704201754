import {useTranslation} from 'react-i18next'
import {Dispatch, FC, SetStateAction} from 'react'
import {ExperiencesVideoFooter} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ProgressBar} from '@/features/experiences-video/components/progress-bar/ProgressBar.tsx'
import {RequirementsModalStep} from '@/features/experiences-video/components/host-friend-requirements-modal/HostFriendRequirementsModal.tsx'
import {
    StyledBody,
    StyledHostFriendRequirementsModalPerspectiveStep,
    StyledParagraph,
    StyledTitle
} from '@/features/experiences-video/components/host-friend-requirements-modal-prespective-step/style.ts'
import {PerspectiveVideos} from '@/features/experiences-video/components/prespective-videos/PerspectiveVideos.tsx'

interface HostFriendRequirementsModalPerspectiveStepProps {
    setStep: Dispatch<SetStateAction<RequirementsModalStep>>
}
export const HostFriendRequirementsModalPerspectiveStep: FC<HostFriendRequirementsModalPerspectiveStepProps> = ({
    setStep
}) => {
    const {t} = useTranslation()

    return (
        <StyledHostFriendRequirementsModalPerspectiveStep>
            <StyledTitle>
                {t('experiences_video:host_friend_get_ready:requirements_modal:perspective:title')}
            </StyledTitle>
            <StyledBody>
                <StyledBody style={{paddingTop: 0, paddingBottom: 0, height: 'auto'}}>
                    <StyledParagraph>
                        {t('experiences_video:host_friend_get_ready:requirements_modal:perspective:description')}
                    </StyledParagraph>
                </StyledBody>
                <PerspectiveVideos scrollSpacing={4} />
            </StyledBody>
            <ExperiencesVideoFooter style={{maxWidth: '100%'}}>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <Flexbox width="100%" gap={1}>
                        <ProgressBar percentage={100} />
                        <ProgressBar percentage={100} />
                        <ProgressBar percentage={0} />
                    </Flexbox>
                    <Flexbox direction="column" gap={4.5} width="100%">
                        <Flexbox justify="space-between" width="100%">
                            <Button variant="tertiary" onClick={() => setStep('technical')}>
                                {t('commons:back')}
                            </Button>
                            <Button variant="primary" onClick={() => setStep('shootList')}>
                                {t('commons:continue')}
                            </Button>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </ExperiencesVideoFooter>
        </StyledHostFriendRequirementsModalPerspectiveStep>
    )
}
