import {ExperiencesVideoModalBody} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {
    QuizModalAnswerResponse,
    QuizModalFooter,
    QuizModalQuestion,
    QuizModalQuestionHint
} from '@/features/experiences-video/components/quiz-modal-atoms/QuizModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import verticallyIcon from '@assets/images/experiences-video/phone_portrait_icon.svg'
import horizontallyIcon from '@assets/images/experiences-video/phone_landscape_icon.svg'
import {
    StyledPhoneAnswerLabelWrapper,
    StyledVideoAnswerItem
} from '@/features/experiences-video/components/quiz-modal-phone-step/style.ts'
import {useExperiencesVideoQuizStore} from '@/features/experiences-video/stores/experiencesVideoQuiz.ts'
import {CheckCircleBrokenIcon, XCircleIcon} from '@components/ui/icon'

export const QuizModalPhoneStep = () => {
    const {t} = useTranslation()
    const setPhoneAnswer = useExperiencesVideoQuizStore(store => store.setPhoneAnswer)
    const setStep = useExperiencesVideoQuizStore(store => store.setStep)
    const phoneAnswer = useExperiencesVideoQuizStore(store => store.phoneAnswer)

    return (
        <>
            <ExperiencesVideoModalBody>
                <Flexbox direction={'column'} gap={2}>
                    <QuizModalQuestion>
                        {t('experiences_video:host_friend_get_ready:quiz:phone:question')}
                    </QuizModalQuestion>
                    <QuizModalQuestionHint>
                        {t('experiences_video:host_friend_get_ready:quiz:tap_option')}
                    </QuizModalQuestionHint>
                </Flexbox>
                <Flexbox gap={4} width={'100%'} justify={'stretch'}>
                    <StyledVideoAnswerItem
                        value={'vertically'}
                        onChange={() => setPhoneAnswer('vertically')}
                        disabled={!!phoneAnswer && phoneAnswer != 'vertically'}
                        checked={phoneAnswer == 'vertically'}
                        name={'phoneAnswer'}
                        type="radio"
                        label={
                            <Flexbox direction="column" gap={4} align={'center'}>
                                {phoneAnswer == 'vertically' && (
                                    <StyledPhoneAnswerLabelWrapper $isCorrect>
                                        <CheckCircleBrokenIcon size={20} />
                                    </StyledPhoneAnswerLabelWrapper>
                                )}
                                <img src={verticallyIcon} alt={'icon'} />
                                {t('experiences_video:host_friend_get_ready:quiz:phone:vertically')}
                            </Flexbox>
                        }
                    />
                    <StyledVideoAnswerItem
                        value={'horizontally'}
                        onChange={() => setPhoneAnswer('horizontally')}
                        disabled={!!phoneAnswer && phoneAnswer != 'horizontally'}
                        checked={phoneAnswer == 'horizontally'}
                        name={'phoneAnswer'}
                        type="radio"
                        label={
                            <Flexbox direction="column" gap={4} align={'center'}>
                                {phoneAnswer == 'horizontally' && (
                                    <StyledPhoneAnswerLabelWrapper>
                                        <XCircleIcon size={20} />
                                    </StyledPhoneAnswerLabelWrapper>
                                )}
                                <img src={horizontallyIcon} alt={'icon'} />
                                {t('experiences_video:host_friend_get_ready:quiz:phone:horizontally')}
                            </Flexbox>
                        }
                    />
                </Flexbox>
                {phoneAnswer && (
                    <QuizModalAnswerResponse
                        variant={phoneAnswer == 'vertically' ? 'success' : 'danger'}
                        title={t(
                            `experiences_video:host_friend_get_ready:quiz:${
                                phoneAnswer == 'vertically' ? 'answer_correct' : 'answer_incorrect'
                            }`
                        )}
                        paragraph={t('experiences_video:host_friend_get_ready:quiz:phone:description')}
                    />
                )}
            </ExperiencesVideoModalBody>
            <QuizModalFooter progressPercentages={[100, 0, 0, 0, 0]}>
                <Button onClick={() => setStep('upload')} disabled={!phoneAnswer}>
                    {t('experiences_video:host_friend_get_ready:quiz:next_question')}
                </Button>
            </QuizModalFooter>
        </>
    )
}
