import styled, {css} from 'styled-components'

export const StyledPerspectiveCard = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${spacing * 2}px;

        & h4 {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledPerspectiveVideoWrapper = styled.div(
    () => css`
        position: relative;
        border-radius: 8px;
        width: 100%;
        height: 180px;
    `
)
export const StyledPerspectiveVideo = styled.video(
    ({theme: {palette}}) => css`
        border-radius: 8px;
        width: 120px;
        height: 100%;
        object-fit: cover;
        background-color: ${palette.neutral['900']};
        pointer-events: none;
    `
)
