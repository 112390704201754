import {
    ExperiencesVideoActionCard,
    ExperiencesVideoBody,
    ExperiencesVideoFooter,
    ExperiencesVideoParagraph,
    ExperiencesVideoTitle
} from '@/features/experiences-video/components/experiences-video-atoms/ExperiencesVideoAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Divider from '@components/ui/divider/Divider.tsx'
import {useTheme} from 'styled-components'
import {StyledShootlistTitle} from '@/features/experiences-video/components/host-friend-get-ready-step/style.ts'
import {useExperiencesVideoHostFriendStore} from '@/features/experiences-video/stores/experiencesVideoHostFriend.ts'
import {LocationsRecap} from '@/features/experiences-video/components/locations-recap/LocationsRecap.tsx'
import {raise} from '@utilities/helpers.ts'
import {AnnotationQuestionIcon, CheckIcon, FileCheck02Icon} from '@components/ui/icon'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import i18n from '@/translations/i18n.ts'
import {useState} from 'react'
import {HostFriendRequirementsModal} from '@/features/experiences-video/components/host-friend-requirements-modal/HostFriendRequirementsModal.tsx'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {QuizModal} from '@/features/experiences-video/components/quiz-modal/QuizModal.tsx'
import {CompletedRequirementsModal} from '@/features/experiences-video/components/completed-requirements-modal/CompletedRequirementsModal.tsx'
import {useExperiencesVideoQuizStore} from '@/features/experiences-video/stores/experiencesVideoQuiz.ts'

const ValidationSchema = z.object({
    shootList: z.array(z.string()).length(5, i18n.t('experiences_video:host_friend_get_ready:check_all_shootlist'))
})
type ValidationSchema = z.infer<typeof ValidationSchema>

export const HostFriendGetReadyStep = () => {
    const shootList = useExperiencesVideoHostFriendStore(store => store.shootList)
    const isRequirementsCompleted = useExperiencesVideoHostFriendStore(store => store.isRequirementsCompleted)
    const resetQuiz = useExperiencesVideoQuizStore(store => store.resetValues)
    const setUserType = useExperiencesVideoQuizStore(store => store.setUserType)
    const {t} = useTranslation()
    const theme = useTheme()
    const [completedRequirementsSlidingModalState, setCompletedRequirementsSlidingModalState] =
        useState<SlidingModalState>('closed')
    const [requirementsSlidingModalState, setRequirementsSlidingModalState] = useState<SlidingModalState>('open')
    const [quizSlidingModalState, setQuizSlidingModalState] = useState<SlidingModalState>('closed')
    const form = useForm<ValidationSchema>({
        defaultValues: {
            shootList: []
        },
        resolver: zodResolver(ValidationSchema)
    })

    const elementsAnswer = useExperiencesVideoQuizStore(store => store.elementsAnswer)

    return (
        <>
            <div style={{overflowY: 'auto', height: '100%'}}>
                <ExperiencesVideoBody style={{height: 'auto'}}>
                    <Flexbox direction="column" gap={2}>
                        <ExperiencesVideoTitle>{t('experiences_video:get_ready_to_film')}</ExperiencesVideoTitle>
                        <ExperiencesVideoParagraph>
                            {t('experiences_video:host_friend_get_ready:description')}
                        </ExperiencesVideoParagraph>
                    </Flexbox>
                    <Flexbox direction="column" gap={3}>
                        <ExperiencesVideoActionCard
                            onClick={() => {
                                isRequirementsCompleted
                                    ? setCompletedRequirementsSlidingModalState('open')
                                    : setRequirementsSlidingModalState('open')
                            }}
                        >
                            <FileCheck02Icon />
                            <Flexbox direction="column" gap={2}>
                                <h3>{t('experiences_video:host_friend_get_ready:requirements')}</h3>
                                <p>{t('experiences_video:host_friend_get_ready:requirements_description')}</p>
                            </Flexbox>
                        </ExperiencesVideoActionCard>
                        <ExperiencesVideoActionCard
                            isCompleted={!!elementsAnswer}
                            onClick={() => {
                                resetQuiz()
                                setUserType('friend')
                                setQuizSlidingModalState('open')
                            }}
                        >
                            {elementsAnswer ? <CheckIcon /> : <AnnotationQuestionIcon />}
                            <Flexbox direction="column" gap={2}>
                                <h3>{t('experiences_video:take_a_quiz')}</h3>
                                <p>{t('experiences_video:take_a_quiz_description')}</p>
                            </Flexbox>
                        </ExperiencesVideoActionCard>
                    </Flexbox>
                </ExperiencesVideoBody>
                <Divider height={8} topSpacing={0} bottomSpacing={0} background={theme.palette.neutral['200']} />
                <ExperiencesVideoBody style={{height: 'auto'}}>
                    <Flexbox direction="column" gap={1}>
                        <StyledShootlistTitle>
                            {t('experiences_video:host_friend_get_ready:shootlist')}
                        </StyledShootlistTitle>
                        <ExperiencesVideoParagraph>
                            {t('experiences_video:host_friend_get_ready:shootlist_description')}
                        </ExperiencesVideoParagraph>
                    </Flexbox>
                    <LocationsRecap
                        formRegister={form.register('shootList')}
                        formErrorMessage={form.formState.errors.shootList?.message}
                        shootList={{...shootList, venue: shootList.venue ?? raise('venue is nullish')}}
                    />
                </ExperiencesVideoBody>
            </div>
            <ExperiencesVideoFooter>
                <Button fullWidth onClick={form.handleSubmit(console.log)}>
                    {t('experiences_video:host_friend_get_ready:upload_clips')}
                </Button>
            </ExperiencesVideoFooter>

            {completedRequirementsSlidingModalState != 'closed' && (
                <CompletedRequirementsModal
                    slidingModalState={completedRequirementsSlidingModalState}
                    setSlidingModalState={setCompletedRequirementsSlidingModalState}
                />
            )}
            {requirementsSlidingModalState != 'closed' && (
                <HostFriendRequirementsModal
                    slidingModalState={requirementsSlidingModalState}
                    setSlidingModalState={setRequirementsSlidingModalState}
                />
            )}
            {quizSlidingModalState != 'closed' && (
                <QuizModal slidingModalState={quizSlidingModalState} setSlidingModalState={setQuizSlidingModalState} />
            )}
        </>
    )
}
