import axios from '@/axiosInstance.ts'
import {ExperiencesVideo, ExperiencesVideoUrlParams} from '@/features/experiences-video/types.ts'
import {ExperiencesVideoQuizStoreValues} from '@/features/experiences-video/stores/experiencesVideoQuiz.ts'
import {ResponseType} from '@/types/commons.ts'

/**
 * httpHostExperienceProposals
 * Here we will fetch the list of the experiences trailers proposals
 */
export interface HttpGetHostExperienceProposalsOptions {
    urlParams: {
        hostCode: number
        hostPassword: string
    }
    params: {
        response_type: ResponseType
    }
}
export const httpGetHostExperienceProposals = ({urlParams, params}: HttpGetHostExperienceProposalsOptions) => {
    return axios.get(`/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences`, {
        params
    })
}

export interface HttpStoreHostExperienceOptions {
    urlParams: {
        hostCode: number
        hostPassword: string
        experiencesVideoId: number
    }
}
export const httpStoreHostExperience = ({urlParams}: HttpStoreHostExperienceOptions) => {
    return axios.post(
        `/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences/${urlParams.experiencesVideoId}/store`
    )
}

export const httpGetExperiencesVideo = ({urlParams}: {urlParams: ExperiencesVideoUrlParams}) =>
    axios.get(
        `host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences/${urlParams.experiencesVideoId}`
    )

export interface HttpUpdateExperiencesVideoOptions {
    urlParams: ExperiencesVideoUrlParams
    payload: {data: ExperiencesVideo['data']}
}
export const httpUpdateExperiencesVideo = ({urlParams, payload}: HttpUpdateExperiencesVideoOptions) =>
    axios.post(
        `host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences/${urlParams.experiencesVideoId}/update`,
        payload
    )

export interface HttpCompleteExperiencesVideoQuizOptions {
    urlParams: ExperiencesVideoUrlParams
    payload: {
        user_type: 'friend' | 'host'
        data: ExperiencesVideoQuizStoreValues
    }
}
export const httpCompleteExperiencesVideoQuiz = ({urlParams, payload}: HttpCompleteExperiencesVideoQuizOptions) =>
    axios.post(
        `host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/experiences/${urlParams.experiencesVideoId}/quiz-completed`,
        payload
    )
