import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FC, ReactNode} from 'react'
import {
    StyledNumberContainer,
    StyledStepDescription,
    StyledStepImgContainer,
    StyledStepTitle
} from '@/features/experiences-video/components/intro-steps-timeline/style.ts'

interface IntroStepsTimelineProps {
    number: number
    title: ReactNode
    paragraph: ReactNode
    image: string
}
export const IntroStepsTimeline: FC<IntroStepsTimelineProps> = ({number, title, paragraph, image}) => {
    return (
        <Flexbox gap={4} align={'end'} justify={'space-between'}>
            <Flexbox gap={3}>
                <StyledNumberContainer>{number}</StyledNumberContainer>
                <Flexbox direction={'column'} gap={1}>
                    <StyledStepTitle>{title}</StyledStepTitle>
                    <StyledStepDescription>{paragraph}</StyledStepDescription>
                </Flexbox>
            </Flexbox>
            <StyledStepImgContainer src={image} alt={'icon'} />
        </Flexbox>
    )
}
