import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SlidingModal} from '@components/commons/sliding-modal/SlidingModal.tsx'

export const StyledSlidingModal = styled(SlidingModal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
`

export const StyledTrailerExampleVideoBody = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
        height: 100%;
    `
)
